import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SingupAcc } from '../interfaces/singup-acc';
import { SignupResetPasData } from '../interfaces/signup-reset-pas-data.model';
import { Router } from '@angular/router';
import { SignupResetPassReturnData } from '../interfaces/signup-reset-pass-return-data.model';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  constructor(private http: HttpClient, private router: Router) {}

  onSignup(acc: SingupAcc) {
    return this.http.post(
      `${environment.API_JOOBZZ}${environment.API_POST_REGISTER}`,
      acc
    );
  }

  onSetPassword(setPassData: {
    password: string;
    email: string | null;
    token: string | null;
  }) {
    return this.http.post<SignupResetPassReturnData>(
      `${environment.API_JOOBZZ}${environment.API_POST_RESETPASSWORD}`,
      setPassData
    );
  }
}
