import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { CertificateData } from '../interfaces/certificate-data.model';
import { Country } from '../interfaces/country.model';
import { Degree } from '../interfaces/degree.model';
import { DrivingLicense } from '../interfaces/driving-license.model';
import { EducationalInstitutions } from '../interfaces/educational-institutions.model';
import { EmplyoymentType } from '../interfaces/emplyoyment-type.model';
import { Indystry } from '../interfaces/indystry.model';
import { ItKnowledgeLevels } from '../interfaces/it-knowledge-levels.model';
import { ItTools } from '../interfaces/it-tools.model';
import { JobTitle } from '../interfaces/job-title.model';
import { Language } from '../interfaces/language.model';
import { Place } from '../interfaces/place.model';
import { Region } from '../interfaces/region.model';
import { SingleSelectId } from '../interfaces/single-select-id.model';
import { Skill } from '../interfaces/skill.model';
import { WorkPermit } from '../interfaces/work-permit.model';
import { FunctionWithinCompany } from '../interfaces/function-within-company.model';
import { SubscriptionPlanType } from '../interfaces/subscription-plan-type.model';

@Injectable({
  providedIn: 'root',
})
export class DropdownDataService {
  private refreshSkills$ = new BehaviorSubject<void>(undefined);
  private refreshCities$ = new BehaviorSubject<void>(undefined);
  private refreshRegions$ = new BehaviorSubject<void>(undefined);
  private refreshPlaces$ = new BehaviorSubject<void>(undefined);
  private refreshDrivingLicences$ = new BehaviorSubject<void>(undefined);
  private refreshTypesOfEmployment$ = new BehaviorSubject<void>(undefined);
  private refreshCertificates$ = new BehaviorSubject<void>(undefined);
  private refershWorkPermits$ = new BehaviorSubject<void>(undefined);
  private refreshJobTitles$ = new BehaviorSubject<void>(undefined);
  private refreshIndustries$ = new BehaviorSubject<void>(undefined);
  private refreshLanguages$ = new BehaviorSubject<void>(undefined);
  private refreshItTools$ = new BehaviorSubject<void>(undefined);
  private refreshDegrees$ = new BehaviorSubject<void>(undefined);
  private refreshEduInstitutions$ = new BehaviorSubject<void>(undefined);
  private refreshItKnowledgeLevels$ = new BehaviorSubject<void>(undefined);
  private refreshFunctionsWithinCompany$ = new BehaviorSubject<void>(undefined);
  private refreshSubscriptionTypes$ = new BehaviorSubject<void>(undefined);

  private citiz$ = this.http
    .get<Country[]>(`${environment.API_JOOBZZ}${environment.API_GET_COUNTRIES}`)
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.countryId };
        });
      })
    )
    .pipe(
      map((e) => {
        const first = e.filter((e) => e.id === 1);
        const removed = e.filter((e) => e.id !== 1);
        return first.concat(removed);
      })
    );

  public citizenships$: Observable<SingleSelectId[]> = this.refreshCities$.pipe(
    switchMap(() => this.citiz$),
    shareReplay()
  );

  private places$ = this.http.get<Place[]>(
    `${environment.API_JOOBZZ}${environment.API_GET_PLACES}`
  );

  public placesAll$: Observable<Place[]> = this.refreshPlaces$.pipe(
    switchMap(() => this.places$),
    shareReplay()
  );

  private regions$ = this.http
    .get<Region[]>(`${environment.API_JOOBZZ}${environment.API_GET_REGIONS}`)
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.regionId };
        });
      })
    );

  public regionsAll$: Observable<SingleSelectId[]> = this.refreshRegions$.pipe(
    switchMap(() => this.regions$),
    shareReplay()
  );

  private drivingLicences$ = this.http
    .get<DrivingLicense[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_DRIVING_LICENSES}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name.trim(), id: e.categoryDrivingLicenceId };
        });
      })
    )
    .pipe(
      map((e) => {
        const first = e.filter((e) => e.id === 1);
        const removed = e.filter((e) => e.id !== 1);
        return first.concat(removed);
      })
    );

  public drivingLicencesAll$: Observable<SingleSelectId[]> =
    this.refreshDrivingLicences$.pipe(
      switchMap(() => this.drivingLicences$),
      shareReplay()
    );

  private typesOfEmplyment$ = this.http
    .get<EmplyoymentType[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_EMPLOYMENT_TYPE}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.typeOfEmploymentId };
        });
      })
    );

  public typesOfEmplymentAll$: Observable<SingleSelectId[]> =
    this.refreshTypesOfEmployment$.pipe(
      switchMap(() => this.typesOfEmplyment$),
      shareReplay()
    );

  private certs$ = this.http
    .get<CertificateData[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_CERTIFICATIONS}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.certificateId };
        });
      })
    );

  public certsAll$: Observable<SingleSelectId[]> =
    this.refreshCertificates$.pipe(
      switchMap(() => this.certs$),
      shareReplay()
    );

  private workPermits$ = this.http
    .get<WorkPermit[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_WORK_PERMITS}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.workPermitId };
        });
      })
    );

  public workPermitsAll$: Observable<SingleSelectId[]> =
    this.refershWorkPermits$.pipe(
      switchMap(() => this.workPermits$),
      shareReplay()
    );

  private skillSets$ = this.http
    .get<Skill[]>(`${environment.API_JOOBZZ}${environment.API_GET_SKILLS}`)
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.skillSetId };
        });
      })
    );

  public skills$: Observable<SingleSelectId[]> = this.refreshSkills$.pipe(
    switchMap(() => this.skillSets$),
    shareReplay()
  );

  private jobTitles$ = this.http
    .get<JobTitle[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_JOB_TITLES}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.jobTitleId };
        });
      })
    );

  public jobTitlesAll$: Observable<SingleSelectId[]> =
    this.refreshJobTitles$.pipe(
      switchMap(() => this.jobTitles$),
      shareReplay()
    );

  private industries$ = this.http
    .get<Indystry[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_INDUSTRIES}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.industryId };
        });
      })
    );

  public industriesAll$: Observable<SingleSelectId[]> =
    this.refreshIndustries$.pipe(
      switchMap(() => this.industries$),
      shareReplay()
    );

  private degrees$ = this.http
    .get<Degree[]>(`${environment.API_JOOBZZ}${environment.API_GET_DEGREES}`)
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.degreeId };
        });
      })
    );

  public degreesAll$: Observable<SingleSelectId[]> = this.refreshDegrees$.pipe(
    switchMap(() => this.degrees$),
    shareReplay()
  );

  private eduInstitutions1$ = this.http.get<EducationalInstitutions[]>(
    `${environment.API_JOOBZZ}${environment.API_GET_EDUCATIONAL_INSTITUTIONS}`
  );

  public eduInstitutionsAll1$: Observable<EducationalInstitutions[]> =
    this.refreshEduInstitutions$.pipe(
      switchMap(() => this.eduInstitutions1$),
      shareReplay()
    );

  private eduInstitutions$ = this.http
    .get<EducationalInstitutions[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_EDUCATIONAL_INSTITUTIONS}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.educationInstitutionId };
        });
      })
    );

  public eduInstitutionsAll$: Observable<SingleSelectId[]> =
    this.refreshEduInstitutions$.pipe(
      switchMap(() => this.eduInstitutions$),
      shareReplay()
    );

  private languages$ = this.http
    .get<Language[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_LANGUAGES}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.languageId };
        });
      })
    );

  public languagesAll$: Observable<SingleSelectId[]> =
    this.refreshLanguages$.pipe(
      switchMap(() => this.languages$),
      shareReplay()
    );

  private itTools$ = this.http
    .get<ItTools[]>(`${environment.API_JOOBZZ}${environment.API_GET_IT_TOOLS}`)
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.itToolId };
        });
      })
    );

  public itToolsAll$: Observable<SingleSelectId[]> = this.refreshItTools$.pipe(
    switchMap(() => this.itTools$),
    shareReplay()
  );

  private itKnowledgeLevels$ = this.http
    .get<ItKnowledgeLevels[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_IT_KNOWLEDGE_LEVELS}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.itKnowledgeLevelId };
        });
      })
    );

  public itKnowledgeLevelsAll$: Observable<SingleSelectId[]> =
    this.refreshItKnowledgeLevels$.pipe(
      switchMap(() => this.itKnowledgeLevels$),
      shareReplay()
    );

  private functionWithinCompany$ = this.http
    .get<FunctionWithinCompany[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_FUNCTION_WITHIN_COMPANIES}`
    )
    .pipe(
      map((collection) => {
        return collection.map((e) => {
          return { name: e.name, id: e.functionWithinCompanyId };
        });
      })
    );

  public functionWithinCompanyAll$: Observable<SingleSelectId[]> =
    this.refreshFunctionsWithinCompany$.pipe(
      switchMap(() => this.functionWithinCompany$),
      shareReplay()
    );

  private subscriptionTypes$ = this.http.get<SubscriptionPlanType[]>(
    `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_SUBSCRIPTTION_PLANS}`
  );

  public subscriptionTypesAll$: Observable<SubscriptionPlanType[]> =
    this.refreshSubscriptionTypes$.pipe(
      switchMap(() => this.subscriptionTypes$),
      shareReplay()
    );

  constructor(private http: HttpClient) {}

  refreshCitizenship() {
    this.refreshCities$.next();
  }

  refreshRegions() {
    this.refreshRegions$.next();
  }

  refreshPlaces() {
    this.refreshPlaces$.next();
  }

  refreshWorkPermits() {
    this.refershWorkPermits$.next();
  }

  refreshDrivingLiceneces() {
    this.refreshDrivingLicences$.next();
  }

  refreshTypeOfEmployments() {
    this.refreshTypesOfEmployment$.next();
  }

  refreshJobTitles() {
    this.refreshJobTitles$.next();
  }

  refreshIndustries() {
    this.refreshIndustries$.next();
  }

  refreshLanguages() {
    this.refreshLanguages$.next();
  }

  refreshSkills() {
    this.refreshSkills$.next();
  }

  refreshItTools() {
    this.refreshItTools$.next();
  }

  refreshDegrees() {
    this.refreshDegrees$.next();
  }

  refreshEducationalInstitutions() {
    this.refreshEduInstitutions$.next();
  }

  refreshCertificates() {
    this.refreshCertificates$.next();
  }

  refreshitKnowledgeLevels() {
    this.refreshItKnowledgeLevels$.next();
  }

  refreshFunctionsWithinCompany() {
    this.refreshFunctionsWithinCompany$.next();
  }

  refreshSubscriptions() {
    this.refreshSubscriptionTypes$.next();
  }

  getRegionByCountryId(countryId: number) {
    return this.http
      .get<Region[]>(
        `${environment.API_JOOBZZ}${environment.API_GET_REGIONS_BY_CONTRY}/${countryId}`
      )
      .pipe(
        map((collection) => {
          return collection.map((e) => {
            return { name: e.name, id: e.regionId };
          });
        })
      );
  }

  getPostalCodesByRegionId(regionId: number) {
    return this.http
      .get<Place[]>(
        `${environment.API_JOOBZZ}${environment.API_GET_PLACES_BY_REGION}/${regionId}`
      )
      .pipe(
        map((collection) => {
          return collection.map((e) => {
            return { name: e.postalCode, id: e.placeId };
          });
        })
      );
  }
}
