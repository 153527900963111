import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CompanyInfoService } from '../services/company-info.service';
import { AdminInfoService } from '../services/admin-info.service';

export const adminAndCompanyGuard: CanActivateFn = (route, state) => {
  const companyInfoService = inject(CompanyInfoService);
  const adminInfoService = inject(AdminInfoService);
  const router = inject(Router);

  if (adminInfoService.isAdmin() || companyInfoService.isRecruiter()) {
    return true;
  } else {
    return router.navigate(['']);
  }
};
