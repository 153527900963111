<header class="bg-blue">
  <div class="container px-3 bg-blue">
    <div class="flex items-center relative justify-between">
      <div class="flex py-3 flex-shrink-1 z-30">
        <a
          class="max-w-[140px] sm:max-w-none mr-2 sm:mr-0"
          routerLink="client/settings"
        >
          <svg
            width="175"
            height="45"
            viewBox="0 0 175 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="h-auto max-w-full"
          >
            <path
              d="M11.9736 9.17463V35.4946C11.9736 40.8464 9.64918 44.3133 4.78065 44.3133C3.07919 44.3106 1.4163 43.8062 0 42.8633L1.92896 38.6978C2.72718 39.1146 3.61663 39.326 4.51701 39.3129C6.2746 39.2251 6.66567 37.8629 6.66567 35.499V9.17463H11.9736ZM6.18233 3.12412C6.18233 7.28961 12.4965 7.28961 12.4965 3.12412C12.4965 -1.04137 6.18233 -1.04137 6.18233 3.12412Z"
              fill="white"
            />
            <path
              d="M108.997 20.098C108.997 26.3286 104.744 31.3729 97.722 31.3729C90.7004 31.3729 86.491 26.3286 86.491 20.098C86.491 13.8673 90.7883 8.823 97.678 8.823C104.568 8.823 108.993 13.9112 108.993 20.098H108.997ZM91.8604 20.098C91.8604 23.389 93.8333 26.4604 97.7396 26.4604C101.646 26.4604 103.619 23.3846 103.619 20.098C103.619 16.8113 101.338 13.6959 97.7396 13.6959C93.8685 13.6959 91.834 16.8552 91.834 20.098H91.8604Z"
              fill="white"
            />
            <path
              d="M116.678 0.228516V12.2021C117.948 9.96558 121.511 8.73526 123.783 8.73526C130.101 8.73526 134.794 12.5932 134.794 20.0542C134.794 27.5151 130.014 31.373 123.651 31.373C121.015 31.373 118.343 30.4942 116.678 27.9062L116.326 30.8897H111.326V0.228516H116.678ZM117.029 20.0542C117.029 23.956 119.925 26.4166 123.304 26.4166C126.683 26.4166 129.455 23.8286 129.455 20.0542C129.455 16.2797 126.736 13.7356 123.304 13.7356C122.472 13.7186 121.644 13.8708 120.873 14.1829C120.101 14.495 119.4 14.9605 118.814 15.5513C118.227 16.142 117.766 16.8457 117.46 17.6197C117.153 18.3937 117.007 19.2219 117.029 20.0542Z"
              fill="white"
            />
            <path
              d="M154.576 9.26245V11.8066L144.619 25.6256H154.668V30.8984H136.46V28.6531L146.812 14.153H137.251V9.26685H154.576V9.26245Z"
              fill="white"
            />
            <path
              d="M174.626 9.26245V11.8066L164.669 25.6256H174.714V30.8984H156.509V28.6531L166.861 14.153H157.305V9.26685H174.63L174.626 9.26245Z"
              fill="white"
            />
            <path
              d="M72.8214 31.7772H26.7461C23.7395 31.7772 20.856 30.5828 18.73 28.4568C16.604 26.3308 15.4097 23.4473 15.4097 20.4407C15.4097 17.4341 16.604 14.5506 18.73 12.4246C20.856 10.2986 23.7395 9.10425 26.7461 9.10425H72.8214C75.828 9.10425 78.7115 10.2986 80.8375 12.4246C82.9635 14.5506 84.1579 17.4341 84.1579 20.4407C84.1579 23.4473 82.9635 26.3308 80.8375 28.4568C78.7115 30.5828 75.828 31.7772 72.8214 31.7772ZM26.7461 13.9596C25.8805 13.9371 25.0192 14.0881 24.2129 14.4038C23.4066 14.7195 22.6717 15.1934 22.0515 15.7977C21.4313 16.4019 20.9383 17.1243 20.6018 17.9221C20.2652 18.7199 20.0918 19.577 20.0918 20.4429C20.0918 21.3088 20.2652 22.1659 20.6018 22.9637C20.9383 23.7616 21.4313 24.4839 22.0515 25.0882C22.6717 25.6924 23.4066 26.1664 24.2129 26.4821C25.0192 26.7977 25.8805 26.9488 26.7461 26.9262H72.8214C74.5117 26.8822 76.1179 26.1799 77.2979 24.9688C78.4778 23.7577 79.1381 22.1337 79.1381 20.4429C79.1381 18.7521 78.4778 17.1281 77.2979 15.917C76.1179 14.706 74.5117 14.0036 72.8214 13.9596H26.7461Z"
              fill="white"
            />
            <path
              d="M33.3064 24.2679L31.5884 22.5498C32.2319 21.5965 32.5033 20.4402 32.3511 19.3001C32.1989 18.1601 31.6336 17.1155 30.7625 16.3644C29.8915 15.6133 28.7751 15.2079 27.625 15.2251C26.475 15.2423 25.3712 15.6809 24.5229 16.4577C23.6747 17.2345 23.1409 18.2955 23.0229 19.4396C22.9048 20.5837 23.2106 21.7314 23.8823 22.665C24.5541 23.5987 25.545 24.2534 26.6673 24.5051C27.7896 24.7568 28.9653 24.588 29.9714 24.0306L31.7554 25.8145C31.9616 26.0208 32.2414 26.1367 32.5331 26.1367C32.8248 26.1367 33.1046 26.0208 33.3108 25.8145C33.5171 25.6083 33.633 25.3285 33.633 25.0368C33.633 24.7451 33.5171 24.4653 33.3108 24.2591L33.3064 24.2679ZM25.2127 19.9398C25.2092 19.4464 25.3526 18.9632 25.6245 18.5515C25.8965 18.1399 26.2847 17.8184 26.7398 17.628C27.195 17.4376 27.6965 17.3868 28.1805 17.4822C28.6646 17.5776 29.1094 17.8148 29.4582 18.1637C29.8071 18.5126 30.0443 18.9573 30.1397 19.4414C30.2351 19.9254 30.1844 20.4269 29.994 20.8821C29.8036 21.3372 29.4821 21.7255 29.0704 21.9974C28.6587 22.2693 28.1755 22.4127 27.6821 22.4092C27.029 22.4035 26.4042 22.1415 25.9423 21.6796C25.4805 21.2177 25.2185 20.5929 25.2127 19.9398Z"
              fill="#5BB3E4"
            />
          </svg>
        </a>
      </div>
      <nav
        class="fixed z-20 inset-0 min-h-screen min-w-full xl:min-h-full xl:min-w-min px-3 xl:px-0 xl:static flex flex-col xl:flex-row text-center items-center xl:items-end xl:justify-between xl:flex-1 transition-transform xl:translate-x-0 bg-blue gap-y-9 xl:gap-y-0 xl:self-end"
        [ngClass]="isMenuShown"
      >
        <ul
          class="flex-col mt-24 xl:mt-0 xl:flex-row flex gap-y-3 xl:gap-y-0 text-uranian-blue font-semibold xl:font-medium text-lg xl:mx-auto relative xl:items-end after:absolute after:w-[310px] after:h-px after:bg-gray-light after:-bottom-5 after:left-1/2 after:-translate-x-1/2 xl:after:hidden"
        >
          <li
            class="text-xl xl:px-[18px] pt-3 xl:self-end pb-5 cursor-pointer"
            [routerLink]="['client/settings']"
            routerLinkActive="active"
            (click)="closeMenu()"
          >
            <a>
              {{ 'client.menu.menu1' | translate }}
            </a>
          </li>
          <li
            class="text-xl xl:px-[18px] pt-3 xl:self-end pb-5 cursor-pointer"
            [routerLink]="['client/notifications']"
            routerLinkActive="active"
            (click)="closeMenu()"
          >
            <a>{{ 'client.menu.menu2' | translate }}</a>
          </li>
          <li
            class="text-xl xl:px-[18px] pt-3 xl:self-end pb-5 cursor-pointer"
            [routerLink]="['client/activities']"
            routerLinkActive="active"
            (click)="closeMenu()"
          >
            <a>{{ 'client.menu.menu3' | translate }}</a>
          </li>
          <li
            class="text-xl xl:px-[18px] pt-3 xl:self-end pb-5 cursor-pointer"
            [routerLink]="['client/statistics']"
            routerLinkActive="active"
            (click)="closeMenu()"
          >
            <a>{{ 'client.menu.menu4' | translate }}</a>
          </li>
          <li
            class="text-xl xl:hidden pt-3 xl:self-end pb-5 cursor-pointer"
            [routerLink]="['/client/settings']"
            [queryParams]="{ tab: '10' }"
            (click)="closeMenu()"
          >
            <a> {{ 'client.data.step11.title' | translate }}</a>
          </li>
          <li
            class="text-xl xl:hidden pt-3 xl:self-end pb-5 cursor-pointer"
            (click)="onLogOut()"
          >
            <a> {{ 'client.menu.logout' | translate }}</a>
          </li>
        </ul>
        <!-- <div
          class="flex xl:items-center self-center mt-6 xl:mt-0 xl:pb-3 xl:mr-4"
        >
          <button
            class="text-uranian-blue font-bold xl:font-medium text-xl xl:px-[18px]"
            (click)="onLogOut()"
          >
            {{ 'client.menu.logout' | translate }}
          </button>
        </div> -->
      </nav>

      <div class="flex gap-5 items-center">
        <button
          class="relative z-30 notifications-header-client"
          (click)="onNotificationsClicked()"
          *ngIf="candidateNotificationsAll$ | async as notifications"
        >
          <svg
            class=""
            width="18"
            height="21"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 17V18H0V17L2 15V9C2 5.9 4.03 3.17 7 2.29V2C7 1.46957 7.21071 0.960859 7.58579 0.585786C7.96086 0.210714 8.46957 0 9 0C9.53043 0 10.0391 0.210714 10.4142 0.585786C10.7893 0.960859 11 1.46957 11 2V2.29C13.97 3.17 16 5.9 16 9V15L18 17ZM11 19C11 19.5304 10.7893 20.0391 10.4142 20.4142C10.0391 20.7893 9.53043 21 9 21C8.46957 21 7.96086 20.7893 7.58579 20.4142C7.21071 20.0391 7 19.5304 7 19"
              fill="white"
            />
          </svg>
          <span
            *ngIf="notifications.length > 0"
            class="h-4 w-4 bg-red absolute -top-[7px] -right-[7px] rounded-full grid place-items-center text-white font-semibold text-[9px] leading-none"
          >
            {{ notifications.length }}
          </span>
          <div
            class="absolute w-[215px] -right-[1.05rem] xl:left-1/2 top-8 xl:-translate-x-1/2 text-left bg-white rounded-[4px] pl-3 pr-1.5 py-4 notification-shadow after:absolute after:bg-white after:w-3 after:h-3 after:-top-[5px] after:right-5 xl:after:left-1/2 xl:after:-translate-x-1/2 after:rotate-45"
            [ngClass]="notificationsShown"
          >
            <ul
              class="w-full text-xs space-y-6 text-gray max-h-96 scrollbar-thin"
            >
              <ng-container
                *ngIf="notifications.length > 0; else noNotification"
              >
                <li
                  *ngFor="let notification of notifications"
                  class="relative last:after:hidden after:absolute after:w-full after:h-px after:bg-lavender after:left-0 after:-bottom-3"
                  [routerLink]="['client/notifications']"
                >
                  {{ notification.notificationText }}
                </li>
              </ng-container>
              <ng-template #noNotification>
                <div class="flex justify-center">
                  <p class="text-center font-semibold text-blue px-2 py-1">
                    {{
                      'company.notifications.notification.noNotifications'
                        | translate
                    }}
                  </p>
                </div>
              </ng-template>
            </ul>
          </div>
        </button>

        <button class="block xl:hidden z-30" (click)="onMenuClicked()">
          <svg
            width="26"
            height="18"
            viewBox="0 0 26 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0V3.6H25.2V0H0ZM0 7.2V10.8H25.2V7.2H0ZM0 14.4V18H25.2V14.4H0Z"
              fill="white"
            />
          </svg>
        </button>
        <div
          class="justify-end items-center hidden xl:flex relative logoout-header-company cursor-pointer"
          *ngIf="candidateOverview$ | async as overview"
          (click)="onLogoutMenuClicked()"
        >
          <div class="text-right text-white mr-2.5">
            <h2 class="font-semibold text-sm">
              {{ overview.firstName }}
              {{ overview.lastName }}
            </h2>
            <p
              class="font-medium text-xs whitespace-nowrap overflow-hidden text-ellipsis w-[20ch]"
              matTooltipPosition="left"
              matTooltipClass="custom-tooltip-header"
              matTooltip="{{ overview.jobTitle }}"
            >
              {{ overview.jobTitle }}
            </p>
          </div>
          <div class="rounded-full overflow-hidden">
            <img
              *ngIf="overview.image; else elseTemplate"
              class="inline w-[32px] h-[32px] object-cover"
              src="{{ 'data:image/jpg;base64,' + overview.image }}"
              alt="..."
            />

            <ng-template #elseTemplate>
              <img
                class="inline w-[32px] h-[32px] object-cover"
                src="./assets/img/blank-avatar.png"
                alt="..."
            /></ng-template>
          </div>
          <div
            class="absolute w-[215px] -right-[1.05rem] xl:left-1/2 top-11 xl:-translate-x-1/2 text-left bg-white rounded-[4px] pl-3 pr-1.5 py-4 notification-shadow after:absolute after:bg-white after:w-3 after:h-3 after:-top-[5px] after:right-5 xl:after:left-1/2 xl:after:-translate-x-1/2 after:rotate-45 z-30"
            [ngClass]="logoutMenuShown"
          >
            <ul
              class="w-full text-xs space-y-6 text-gray max-h-96 scrollbar-thin"
            >
              <li
                class="relative last:after:hidden after:absolute after:w-full after:h-px after:bg-lavender after:left-0 after:-bottom-3 cursor-pointer"
                [routerLink]="['/client/settings']"
                [queryParams]="{ tab: '10' }"
              >
                <a> {{ 'client.data.step11.title' | translate }}</a>
              </li>
              <li
                class="relative last:after:hidden after:absolute after:w-full after:h-px after:bg-lavender after:left-0 after:-bottom-3 cursor-pointer"
                (click)="onLogOut()"
              >
                <a> {{ 'client.menu.logout' | translate }}</a>
              </li>
            </ul>
          </div>
        </div>
        <app-lang-select class="z-30"></app-lang-select>
      </div>
    </div>
  </div>
</header>
