import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

import { map, tap } from 'rxjs/operators';
import { ValidatorsService } from '../services/validators.service';

export function usernameValidator(email: ValidatorsService): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return email
      .mailExists(control.value)

      .pipe(map((user) => (!user ? null : { userExists: true })));
  };
}
