import { Component } from '@angular/core';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent {
  panelOpenState = false;
  title = 'Candidate Center';
  notificationsShown = 'hidden';
  constructor(private loginService: LoginService) {}
  onNotificationsClicked() {
    if (this.notificationsShown === 'hidden') {
      this.notificationsShown = '';
    } else {
      this.notificationsShown = 'hidden';
    }
  }

  closeNotifications() {
    this.notificationsShown = 'hidden';
  }

  onLogOut() {
    this.loginService.logOut();
  }
}
