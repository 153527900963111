import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-acc-type-step',
  templateUrl: './acc-type-step.component.html',
  styleUrls: ['./acc-type-step.component.scss'],
})
export class AccTypeStepComponent implements OnInit {
  @Input() stepper = {} as MatStepper;
  constructor(private formBuilder: FormBuilder) {}
  signInstepOne = this.formBuilder.group({
    type: ['', [Validators.required]],
  });

  ngOnInit(): void {
    this.signInstepOne.valueChanges.subscribe((res) => {
      this.stepper.next();
    });
  }
}
