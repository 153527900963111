import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { singnUpDialog } from '../sign-up-dialog/sign-up-dialog.component';
import { LoginService } from './../../services/login.service';
import { DropdownDataService } from 'src/app/services/dropdown-data.service';
import { ResetPasswordRequest } from 'src/app/interfaces/reset-password-request.model';
import { finalize } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { openResetPassDialog } from '../reset-pass-dialog/reset-pass-dialog.component';
import { LangSelectService } from 'src/app/services/lang-select.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent {
  hide = true;
  isSettingNewPass = false;
  loginForm = this.formBuilder.group({
    email: ['', [Validators.required]],
    pass: ['', [Validators.required, Validators.minLength(8)]],
    // email: ['miland86@gmail.com', [Validators.required, Validators.email]],
    // email: ['milan@365.sbs', [Validators.required, Validators.email]],
    // email: [
    //   'mikoo@365oapp.onmicrosoft.com	',
    //   [Validators.required, Validators.email],
    // ],
    // email: ['danijel.bilic@gmail.com', [Validators.required, Validators.email]],
    // email: ['office@qdevs.io', [Validators.required, Validators.email]],
    // email: [
    //   'danijel007.bilic@gmail.com',
    //   [Validators.required, Validators.email],
    // ],
    // pass: ['MILAN#123milan', [Validators.required, Validators.minLength(8)]],
    // email: ['admin', [Validators.required, Validators.email]],
    // pass: ['password', [Validators.required, Validators.minLength(8)]],
  });

  get email() {
    return this.loginForm.controls.email;
  }

  get pass() {
    return this.loginForm.controls.pass;
  }
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private router: Router,
    private dropDownDataService: DropdownDataService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private langSelectService: LangSelectService
  ) {}

  onLogin() {
    const userName = this.loginForm.value.email as string;
    const password = this.loginForm.value.pass as string;
    // this.pass.setErrors({ unatorised: false });
    // this.email.setErrors({ unatorised: false });
    this.loginService
      .onLogin({
        userName: userName,
        password: password,
      })
      .subscribe({
        next: (res) => {
          console.log(res);
          // this.dropDownDataService.refreshCitizenship();
          // this.dropDownDataService.refreshRegions();
          // this.dropDownDataService.refreshPlaces();
          // this.dropDownDataService.refreshWorkPermits();
          // this.dropDownDataService.refreshDrivingLiceneces();
          // this.dropDownDataService.refreshTypeOfEmployments();
          // this.dropDownDataService.refreshJobTitles();
          // this.dropDownDataService.refreshIndustries();
          // this.dropDownDataService.refreshLanguages();
          // this.dropDownDataService.refreshSkills();
          // this.dropDownDataService.refreshItTools();
          // this.dropDownDataService.refreshDegrees();
          // this.dropDownDataService.refreshEducationalInstitutions();
          // this.dropDownDataService.refreshCertificates();
          // this.dropDownDataService.refreshitKnowledgeLevels();
          // this.dropDownDataService.refreshFunctionsWithinCompany();
          // this.dropDownDataService.refreshSubscriptions();
          if (res.isSuccess) {
            localStorage.setItem('authToken', res.token);
            localStorage.setItem('userRole', res.role[0]);
            localStorage.setItem('userId', res.userId);
            localStorage.setItem('userFirstName', res.firstName);
            localStorage.setItem('userLastName', res.lastName);
            localStorage.setItem('userEmail', res.email);
            localStorage.setItem('candidateExists', res.exists.toString());
            if (res.languageId === 0) {
              this.langSelectService.setActiveLang(
                this.langSelectService.getInitialLanguage()
              );
            } else if (res.languageId === 1) {
              this.langSelectService.setActiveLang('en');
            } else if (res.languageId === 2) {
              this.langSelectService.setActiveLang('de');
            } else if (res.languageId === 3) {
              this.langSelectService.setActiveLang('fr');
            }

            if (res.role[0] === 'Candidate') {
              if (res.exists) {
                this.loginService.acitvateClient();
                this.router.navigate(['/client/settings']);
              } else {
                this.router.navigate(['/reg']);
                this.loginService.registeringUser();
              }
            }

            if (res.role[0] === 'Company') {
              localStorage.setItem(
                'isRegruterConfirmed',
                res.isRegruterConfirmed.toString()
              );
              if (res.exists) {
                this.loginService.activateCompany();
                this.router.navigate(['/company/settings']);
                // this.router.navigate(['/company/candidates']);
              } else {
                this.router.navigate(['/company/reg']);
                this.loginService.registeringUser();
              }
            }

            if (res.role[0] === 'Admin') {
              this.loginService.adminLogIn();
            }
            this.dialogRef.close();
          }
        },
        error: (err) => {
          if (err.statusText === 'Unauthorized') {
            this.pass.setErrors({ unatorised: true });
            this.email.setErrors({ unatorised: true });
          }
        },
      });
  }

  goToSignIn() {
    singnUpDialog(this.dialog);
    this.dialogRef.close();
  }

  onChangePassword() {
    openResetPassDialog(this.dialog);
    this.dialogRef.close();
  }
}

export function openLoginDialog(dialog: MatDialog) {
  const config = new MatDialogConfig();
  config.disableClose = true;
  config.autoFocus = true;
  config.maxWidth = 573;
  config.height = '555px';
  config.backdropClass = 'customDialogBackdrop';
  config.panelClass = ['customDialogOverlay', 'loginOverlay'];

  const dialogRef = dialog.open(LoginDialogComponent, config);

  return dialogRef.afterClosed();
}
