import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { RecruiterOverview } from '../interfaces/recruiter-overview.model';
import { SingleNotification } from '../interfaces/single-notification.model';
import { CompanyInfoService } from '../services/company-info.service';
import { CompanyNotificationsService } from '../services/company-notifications.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.scss'],
})
export class CompanyHeaderComponent implements OnInit {
  isMenuShown = '-translate-x-full';
  notificationsShown = 'hidden';
  logoutMenuShown = 'hidden';

  recruiterOverview$: Observable<RecruiterOverview> =
    this.companyInfoService.recruiterOverview$;

  companyNotificationsAll$: Observable<SingleNotification[]> =
    this.companyNotificationsService.companyNotificatinsAll$.pipe(
      map((notifications) => {
        return notifications.filter((e) => {
          return e.read === false;
        });
      })
    );

  constructor(
    private loginService: LoginService,
    private companyInfoService: CompanyInfoService,
    private companyNotificationsService: CompanyNotificationsService,
    private elementRef: ElementRef
  ) {}
  ngOnInit(): void {
    this.companyNotificationsService.getAllCompanyNotifications();
  }

  onMenuClicked() {
    if (this.isMenuShown === '-translate-x-full') {
      this.isMenuShown = '';
    } else {
      this.isMenuShown = '-translate-x-full';
    }
  }
  closeMenu() {
    this.isMenuShown = '-translate-x-full';
  }

  onNotificationsClicked() {
    if (this.notificationsShown === 'hidden') {
      this.notificationsShown = '';
    } else {
      this.notificationsShown = 'hidden';
    }
  }

  onLogoutMenuClicked() {
    if (this.logoutMenuShown === 'hidden') {
      this.logoutMenuShown = '';
    } else {
      this.logoutMenuShown = 'hidden';
    }
  }

  @HostListener('document:click', ['$event']) clickOutside($event: Event) {
    $event.stopPropagation();
    if (
      !this.elementRef.nativeElement
        .querySelector('.notifications-header-company')
        .contains($event.target)
    ) {
      this.closeNotifications();
    }
    if (
      !this.elementRef.nativeElement
        .querySelector('.logoout-header-company')
        .contains($event.target)
    ) {
      this.closeLogout();
    }
  }

  closeNotifications() {
    this.notificationsShown = 'hidden';
  }
  closeLogout() {
    this.logoutMenuShown = 'hidden';
  }

  onLogOut() {
    this.loginService.logOut();
  }
}
