import { MatPasswordStrengthComponent } from '@angular-material-extensions/password-strength';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  showDetails = false;
  showDetails3 = true;

  first = false;
  second = false;
  third = false;
  fourth = false;
  color = '';

  @ViewChild('passwordComponentWithConfirmation', { static: true })
  passwordComponentWithConfirmation = {} as MatPasswordStrengthComponent;
  signInStepFour = {} as FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.signInStepFour = this.formBuilder.group({
      pass: this.passwordComponentWithConfirmation.passwordFormControl,
      confirmPass:
        this.passwordComponentWithConfirmation.passwordConfirmationFormControl,
    });
  }

  onStrengthChanged(strength: number) {
    if (strength > 39) {
      this.first = true;
    } else {
      this.first = false;
    }
    if (strength > 59) {
      this.second = true;
    } else {
      this.second = false;
    }
    if (strength > 79) {
      this.third = true;
    } else {
      this.third = false;
    }
    if (strength > 99) {
      this.fourth = true;
    } else {
      this.fourth = false;
    }
  }
}
