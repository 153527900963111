import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private loginService: LoginService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('auth/login') &&
          !req.url.includes('auth/reg') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.loginService.refreshToken().pipe(
        switchMap((loginToken) => {
          if (loginToken.token === null) {
            this.loginService.logOut();
            this.isRefreshing = false;
            return next.handle(request);
          }
          console.log(loginToken);
          localStorage.setItem('authToken', loginToken.token);

          const cloned = request.clone({
            headers: request.headers.set(
              'Authorization',
              'Bearer ' + loginToken.token
            ),
          });
          this.isRefreshing = false;
          return next.handle(cloned);
        }),
        catchError((error) => {
          console.log(error);
          this.isRefreshing = false;

          if (error.status == '403') {
            this.loginService.logOut();
          }

          return throwError(() => error);
        })
      );
    }

    return next.handle(request);
  }

  // intercept(
  //   req: HttpRequest<unknown>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<unknown>> {
  //   req = req.clone({
  //     withCredentials: true,
  //   });

  //   return next.handle(req).pipe(
  //     catchError((error) => {
  //       if (
  //         error instanceof HttpErrorResponse &&
  //         !req.url.includes('auth/login') &&
  //         !req.url.includes('auth/reg') &&
  //         error.status === 401
  //       ) {
  //         if (!this.isRefreshing) {
  //           this.isRefreshing = true;
  //           this.loginService.refreshToken().subscribe({
  //             next: (res) => {
  //               if (res.token === null) {
  //                 this.loginService.logOut();
  //                 this.isRefreshing = false;
  //                 return next.handle(req);
  //               } else {
  //                 localStorage.setItem('authToken', res.token);
  //                 const cloned = req.clone({
  //                   headers: req.headers.set(
  //                     'Authorization',
  //                     'Bearer ' + res.token
  //                   ),
  //                 });

  //                 this.isRefreshing = false;
  //                 return next.handle(cloned);
  //               }
  //             },
  //             error: (error) => {
  //               console.log(error);
  //               this.loginService.logOut();
  //               this.isRefreshing = false;
  //               return next.handle(req);
  //             },
  //           });

  //           // return this.handle401Error(req, next);
  //         }
  //       }

  //       return throwError(() => error);
  //     })
  //   );
  // }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //   this.loginService.refreshToken().subscribe({
  //     next: (res) => {
  //       console.log(res);
  //       if (res.token === null) {
  //         this.loginService.logOut();
  //         this.isRefreshing = false;
  //         return next.handle(request);
  //       } else {
  //         localStorage.setItem('authToken', res.token);
  //         const cloned = request.clone({
  //           headers: request.headers.set(
  //             'Authorization',
  //             'Bearer ' + res.token
  //           ),
  //         });

  //         this.isRefreshing = false;
  //         return next.handle(cloned);
  //       }
  //     },
  //     error: (error) => {
  //       console.log(error);
  //       this.loginService.logOut();
  //       this.isRefreshing = false;
  //       return next.handle(request);
  //     },
  //   });
  //   // return next.handle(request);
  // }
}
