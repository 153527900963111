<div class="menu w-72 h-screen inline-block fixed top-0 left-0 bg-dark">
  <div class="h-[70px] flex justify-center items-center">
    <a routerLink="/admin/client/candidates">
      <svg
        width="138"
        height="35"
        viewBox="0 0 138 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.40863 7.20925V27.891C9.40863 32.0964 7.58215 34.8206 3.75655 34.8206C2.41957 34.8184 1.1129 34.4221 0 33.6812L1.51574 30.408C2.14297 30.7355 2.84188 30.9016 3.54938 30.8914C4.93047 30.8223 5.23776 29.752 5.23776 27.8944V7.20925H9.40863ZM4.85796 2.45488C4.85796 5.72804 9.8195 5.72804 9.8195 2.45488C9.8195 -0.818292 4.85796 -0.818292 4.85796 2.45488Z"
          fill="white"
        />
        <path
          d="M85.6496 15.7928C85.6496 20.6887 82.3074 24.6524 76.79 24.6524C71.2725 24.6524 67.9648 20.6887 67.9648 15.7928C67.9648 10.8968 71.3416 6.93311 76.7554 6.93311C82.1693 6.93311 85.6462 10.9313 85.6462 15.7928H85.6496ZM72.1841 15.7928C72.1841 18.3788 73.7343 20.7923 76.8038 20.7923C79.8732 20.7923 81.4235 18.3754 81.4235 15.7928C81.4235 13.2101 79.6315 10.7622 76.8038 10.7622C73.7619 10.7622 72.1633 13.2447 72.1633 15.7928H72.1841Z"
          fill="white"
        />
        <path
          d="M91.6859 0.179443V9.58807C92.6837 7.83064 95.4838 6.86388 97.2689 6.86388C102.234 6.86388 105.921 9.89536 105.921 15.7581C105.921 21.6208 102.165 24.6522 97.1653 24.6522C95.0937 24.6522 92.9944 23.9617 91.6859 21.928L91.4096 24.2724H87.4805V0.179443H91.6859ZM91.9621 15.7581C91.9621 18.8241 94.2374 20.7576 96.8925 20.7576C99.5477 20.7576 101.726 18.7239 101.726 15.7581C101.726 12.7922 99.5891 10.7931 96.8925 10.7931C96.2385 10.7797 95.5885 10.8993 94.982 11.1446C94.3755 11.3898 93.8251 11.7556 93.3642 12.2198C92.9032 12.684 92.5412 13.2369 92.3002 13.8451C92.0592 14.4533 91.9442 15.1041 91.9621 15.7581Z"
          fill="white"
        />
        <path
          d="M121.464 7.27832V9.27744L113.64 20.1362H121.537V24.2795H107.229V22.5151L115.363 11.1212H107.85V7.28177H121.464V7.27832Z"
          fill="white"
        />
        <path
          d="M137.216 7.27832V9.27744L129.392 20.1362H137.285V24.2795H122.98V22.5151L131.115 11.1212H123.605V7.28177H137.219L137.216 7.27832Z"
          fill="white"
        />
        <path
          d="M57.2185 24.97H21.0135C18.6509 24.97 16.3851 24.0315 14.7146 22.3609C13.044 20.6904 12.1055 18.4246 12.1055 16.062C12.1055 13.6995 13.044 11.4337 14.7146 9.76314C16.3851 8.09257 18.6509 7.15405 21.0135 7.15405H57.2185C59.5811 7.15405 61.8469 8.09257 63.5174 9.76314C65.188 11.4337 66.1265 13.6995 66.1265 16.062C66.1265 18.4246 65.188 20.6904 63.5174 22.3609C61.8469 24.0315 59.5811 24.97 57.2185 24.97ZM21.0135 10.9693C20.3333 10.9516 19.6564 11.0703 19.0229 11.3183C18.3893 11.5664 17.8118 11.9388 17.3245 12.4136C16.8371 12.8884 16.4498 13.456 16.1853 14.0829C15.9209 14.7098 15.7846 15.3834 15.7846 16.0638C15.7846 16.7442 15.9209 17.4177 16.1853 18.0446C16.4498 18.6715 16.8371 19.2391 17.3245 19.7139C17.8118 20.1887 18.3893 20.5611 19.0229 20.8092C19.6564 21.0573 20.3333 21.1759 21.0135 21.1582H57.2185C58.5467 21.1237 59.8089 20.5717 60.7361 19.6201C61.6632 18.6685 62.1821 17.3924 62.1821 16.0638C62.1821 14.7351 61.6632 13.459 60.7361 12.5074C59.8089 11.5558 58.5467 11.0039 57.2185 10.9693H21.0135Z"
          fill="white"
        />
        <path
          d="M26.1724 19.0694L24.8224 17.7194C25.3281 16.9703 25.5413 16.0617 25.4217 15.1659C25.3021 14.27 24.8579 13.4492 24.1735 12.859C23.489 12.2688 22.6117 11.9503 21.708 11.9638C20.8044 11.9773 19.937 12.3219 19.2705 12.9323C18.604 13.5427 18.1845 14.3764 18.0918 15.2754C17.999 16.1745 18.2393 17.0763 18.7671 17.8099C19.295 18.5436 20.0736 19.058 20.9555 19.2558C21.8374 19.4536 22.7612 19.3209 23.5518 18.883L24.9536 20.2848C25.1157 20.4468 25.3355 20.5379 25.5647 20.5379C25.7939 20.5379 26.0138 20.4468 26.1759 20.2848C26.3379 20.1227 26.429 19.9028 26.429 19.6736C26.429 19.4444 26.3379 19.2246 26.1759 19.0625L26.1724 19.0694ZM19.8125 15.6685C19.8098 15.2808 19.9224 14.9011 20.1361 14.5776C20.3498 14.2541 20.6548 14.0015 21.0125 13.8519C21.3701 13.7023 21.7642 13.6624 22.1446 13.7374C22.5249 13.8123 22.8744 13.9987 23.1486 14.2729C23.4227 14.547 23.6091 14.8965 23.6841 15.2768C23.759 15.6572 23.7192 16.0513 23.5695 16.4089C23.4199 16.7666 23.1673 17.0716 22.8438 17.2853C22.5203 17.499 22.1406 17.6116 21.7529 17.6089C21.2397 17.6044 20.7488 17.3985 20.3858 17.0356C20.0229 16.6727 19.817 16.1817 19.8125 15.6685Z"
          fill="#5BB3E4"
        />
      </svg>
    </a>
  </div>
  <div>
    <mat-accordion class="adimn-nav">
      <mat-expansion-panel class="bg-dark text-gray-light" [expanded]="true">
        <mat-expansion-panel-header class="bg-dark text-gray-light px-0">
          <mat-panel-title class="text-gray-light">
            <svg
              width="27"
              height="20"
              viewBox="0 0 27 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mx-3"
            >
              <path
                opacity="0.3"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.55469 4.44444C5.55469 6.89904 7.54453 8.88888 9.99913 8.88888C12.4537 8.88888 14.4436 6.89904 14.4436 4.44444C14.4436 1.98984 12.4537 0 9.99913 0C7.54453 0 5.55469 1.98984 5.55469 4.44444ZM16.6708 8.88886C16.6708 10.7298 18.1631 12.2222 20.0041 12.2222C21.845 12.2222 23.3374 10.7298 23.3374 8.88886C23.3374 7.04791 21.845 5.55553 20.0041 5.55553C18.1631 5.55553 16.6708 7.04791 16.6708 8.88886Z"
                fill="#A1A5B7"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.98148 11.1111C4.73545 11.1111 0.431398 13.8072 0.000724093 19.1102C-0.0227353 19.3991 0.52968 20 0.808335 20H19.163C19.9977 20 20.0106 19.3283 19.9977 19.1111C19.6721 13.6591 15.3013 11.1111 9.98148 11.1111ZM26.0633 20H21.7818V19.9999C21.7818 17.4989 20.9555 15.1909 19.561 13.334C23.3446 13.3764 26.4336 15.2901 26.6661 19.3333C26.6754 19.4962 26.6661 20 26.0633 20Z"
                fill="#A1A5B7"
              />
            </svg>
            {{ 'admin.candidateCenter.center' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/client/candidates']"
          routerLinkActive="active"
          (click)="title = 'admin.candidateCenter.center'"
        >
          {{ 'admin.candidateCenter.candidates' | translate }}
        </a>
        <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/client/trafic']"
          routerLinkActive="active"
          (click)="title = 'admin.candidateCenter.center'"
        >
          {{ 'admin.candidateCenter.trafic' | translate }}
        </a>
        <!-- <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/client/payments']"
          routerLinkActive="active"
          (click)="title = 'admin.candidateCenter.center'"
        >
          {{ 'admin.candidateCenter.paymentsTotal' | translate }}
        </a> -->
        <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/client/payments-candidate']"
          routerLinkActive="active"
          (click)="title = 'admin.candidateCenter.center'"
        >
          {{ 'admin.candidateCenter.candidatePayments' | translate }}
        </a>
        <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/client/settings']"
          routerLinkActive="active"
          (click)="title = 'admin.candidateCenter.center'"
        >
          {{ 'admin.candidateCenter.canndidateAccSetting' | translate }}
        </a>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div>
    <mat-accordion class="adimn-nav">
      <mat-expansion-panel class="bg-dark text-gray-light">
        <mat-expansion-panel-header class="bg-dark text-gray-light px-0">
          <mat-panel-title class="text-gray-light">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mx-3"
            >
              <rect
                opacity="0.3"
                width="10.9375"
                height="10.9375"
                rx="1.5"
                fill="#A1A5B7"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0625 1.5C14.0625 0.671573 14.7341 0 15.5625 0H23.5C24.3284 0 25 0.671573 25 1.5V9.4375C25 10.2659 24.3284 10.9375 23.5 10.9375H15.5625C14.7341 10.9375 14.0625 10.2659 14.0625 9.4375V1.5ZM0 15.5625C0 14.7341 0.671573 14.0625 1.5 14.0625H9.4375C10.2659 14.0625 10.9375 14.7341 10.9375 15.5625V23.5C10.9375 24.3284 10.2659 25 9.4375 25H1.5C0.671573 25 0 24.3284 0 23.5V15.5625ZM15.5625 14.0625C14.7341 14.0625 14.0625 14.7341 14.0625 15.5625V23.5C14.0625 24.3284 14.7341 25 15.5625 25H23.5C24.3284 25 25 24.3284 25 23.5V15.5625C25 14.7341 24.3284 14.0625 23.5 14.0625H15.5625Z"
                fill="#A1A5B7"
              />
            </svg>

            {{ 'admin.companyCenter.center' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/company/companies']"
          routerLinkActive="active"
          (click)="title = 'admin.companyCenter.center'"
        >
          {{ 'admin.companyCenter.companies' | translate }}
        </a>
        <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/company/trafic']"
          routerLinkActive="active"
          (click)="title = 'admin.companyCenter.center'"
        >
          {{ 'admin.companyCenter.trafic' | translate }}
        </a>
        <!-- <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/company/status']"
          routerLinkActive="active"
          (click)="title = 'admin.companyCenter.center'"
        >
          {{ 'admin.companyCenter.companyStatus' | translate }}
        </a> -->
        <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/company/settings']"
          routerLinkActive="active"
          (click)="title = 'admin.companyCenter.center'"
        >
          {{ 'admin.companyCenter.companyAccSettings' | translate }}
        </a>
        <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/company/confirmation']"
          routerLinkActive="active"
          (click)="title = 'admin.companyCenter.center'"
        >
          {{ 'admin.companyCenter.candidateAccConfirm' | translate }}
        </a>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div>
    <mat-accordion class="adimn-nav">
      <mat-expansion-panel class="bg-dark text-gray-light">
        <mat-expansion-panel-header class="bg-dark text-gray-light px-0">
          <mat-panel-title class="text-gray-light">
            <svg
              class="mx-3"
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.125 25L8.625 21C8.35417 20.8958 8.09917 20.7708 7.86 20.625C7.62 20.4792 7.38542 20.3229 7.15625 20.1562L3.4375 21.7188L0 15.7812L3.21875 13.3438C3.19792 13.1979 3.1875 13.0571 3.1875 12.9212V12.0775C3.1875 11.9425 3.19792 11.8021 3.21875 11.6562L0 9.21875L3.4375 3.28125L7.15625 4.84375C7.38542 4.67708 7.625 4.52083 7.875 4.375C8.125 4.22917 8.375 4.10417 8.625 4L9.125 0H16L16.5 4C16.7708 4.10417 17.0262 4.22917 17.2663 4.375C17.5054 4.52083 17.7396 4.67708 17.9688 4.84375L21.6875 3.28125L25.125 9.21875L21.9062 11.6562C21.9271 11.8021 21.9375 11.9425 21.9375 12.0775V12.9212C21.9375 13.0571 21.9167 13.1979 21.875 13.3438L25.0937 15.7812L21.6562 21.7188L17.9688 20.1562C17.7396 20.3229 17.5 20.4792 17.25 20.625C17 20.7708 16.75 20.8958 16.5 21L16 25H9.125ZM12.625 16.875C13.8333 16.875 14.8646 16.4479 15.7188 15.5938C16.5729 14.7396 17 13.7083 17 12.5C17 11.2917 16.5729 10.2604 15.7188 9.40625C14.8646 8.55208 13.8333 8.125 12.625 8.125C11.3958 8.125 10.3592 8.55208 9.515 9.40625C8.67167 10.2604 8.25 11.2917 8.25 12.5C8.25 13.7083 8.67167 14.7396 9.515 15.5938C10.3592 16.4479 11.3958 16.875 12.625 16.875Z"
                fill="#A1A5B7"
              />
            </svg>

            {{ 'admin.adminCenter.center' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/center/settings']"
          routerLinkActive="active"
          (click)="title = 'admin.adminCenter.center'"
        >
          {{ 'admin.adminCenter.accSettings' | translate }}
        </a> -->
        <a
          class="block font-medium text-sm py-2 px-12 cursor-pointer"
          [routerLink]="['admin/center/crm']"
          routerLinkActive="active"
          (click)="title = 'admin.adminCenter.center'"
        >
          {{ 'admin.adminCenter.crm' | translate }}
        </a>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="mw-full ml-72 flex h-[70px] bg-blue">
  <div class="container flex items-center justify-between">
    <h1 class="font-bold text-white text-2xl">{{ title | translate }}</h1>

    <div class="flex gap-7">
      <div class="flex xl:items-center self-center">
        <button
          class="text-uranian-blue font-bold xl:font-medium text-xl xl:px-[18px]"
          (click)="onLogOut()"
        >
          {{ 'client.menu.logout' | translate }}
        </button>
      </div>
      <!-- <button class="relative z-30" (click)="onNotificationsClicked()">
        <svg
          width="25"
          height="20"
          viewBox="0 0 25 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.0241 16.1949C24.3466 16.4851 24.8603 16.255 24.8586 15.8212L24.8386 10.7604V2.80253C24.8386 1.33753 23.519 0.149902 21.8912 0.149902H8.13682C6.50903 0.149902 5.18945 1.33753 5.18945 2.80253V6.4499H13.6673C15.3242 6.4499 16.6673 7.79305 16.6673 9.4499V13.4131H20.9333L24.0241 16.1949Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.316406 15.9V10.65C0.316406 9.49025 1.36108 8.55005 2.64974 8.55005H11.9831C13.2717 8.55005 14.3164 9.49025 14.3164 10.65V15.9C14.3164 17.0598 13.2717 18 11.9831 18H2.78476L1.17324 19.3882C0.849154 19.6674 0.346917 19.4372 0.346917 19.0094V16.2405C0.326843 16.1297 0.316406 16.016 0.316406 15.9ZM5.0005 12.2C5.0005 11.9239 5.22436 11.7 5.5005 11.7H11.5005C11.7766 11.7 12.0005 11.9239 12.0005 12.2V12.25C12.0005 12.5262 11.7766 12.75 11.5005 12.75H5.5005C5.22436 12.75 5.0005 12.5262 5.0005 12.25V12.2ZM9.0005 13.8001C8.72436 13.8001 8.5005 14.0239 8.5005 14.3001V14.3501C8.5005 14.6262 8.72436 14.8501 9.0005 14.8501H11.5005C11.7766 14.8501 12.0005 14.6262 12.0005 14.3501V14.3001C12.0005 14.0239 11.7766 13.8001 11.5005 13.8001H9.0005Z"
            fill="#B5B5C3"
          />
        </svg>

        <span
          class="h-3.5 w-3.5 bg-red absolute top-1 p-0 m-0 -right-[7px] rounded-full grid place-items-center text-white font-semibold text-[9px] leading-[0]"
        >
          3
        </span>
        <div
          class="absolute w-[215px] -right-[1.05rem] xl:left-1/2 top-12 xl:-translate-x-1/2 text-left bg-white rounded-[4px] px-3 py-4 notification-shadow after:absolute after:bg-white after:w-3 after:h-3 after:-top-[5px] after:right-5 xl:after:left-1/2 xl:after:-translate-x-1/2 after:rotate-45"
          [ngClass]="notificationsShown"
        >
          <ul class="w-full text-xs space-y-6 text-gray">
            <li
              class="relative last:after:hidden after:absolute after:w-full after:h-px after:bg-lavender after:left-0 after:-bottom-3"
            >
              A company has shown interest in your documents.
            </li>
            <li
              class="relative last:after:hidden after:absolute after:w-full after:h-px after:bg-lavender after:left-0 after:-bottom-3"
            >
              <strong>Company XY, Mr/Ms Smith</strong>, is interested in your
              Dossier.
            </li>
            <li
              class="relative last:after:hidden after:absolute after:w-full after:h-px after:bg-lavender after:left-0 after:-bottom-3"
            >
              Your profile was viewed by
              <strong>Company XY, Contact Mr/Mrs</strong>
            </li>
          </ul>
        </div>
      </button> -->
      <div class="justify-end items-center hidden xl:flex">
        <div class="text-right text-white mr-2.5">
          <h2 class="font-semibold text-sm">Mladen Malbasic</h2>
          <p class="font-medium text-xs">Administrator</p>
        </div>
        <div class="rounded-full overflow-hidden">
          <img
            class="inline w-[45px] h-[45px] object-cover"
            src="./assets/img/blank-avatar.png"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
</div>
