<footer class="lg:mt-16 scroll-margin" id="contact">
  <div class="container px-3">
    <div
      class="flex py-10 px-8 flex-col justify-start items-start gap-5 lg:gap-0 lg:flex-row lg:justify-between lg:items-center bg-gradient-to-r from-purple to-blue rounded-xl translate-y-0 lg:-translate-y-16 premium-shadow"
    >
      <div class="">
        <h2 class="text-white font-light text-[30px] mb-0.5">
          {{ 'footer.premiumTitle1' | translate }},
          <span class="font-medium">{{
            'footer.premiumTitle2' | translate
          }}</span>
        </h2>
        <p class="font-medium text-lg text-uranian-blue">
          {{ 'footer.premiumText' | translate }}
        </p>
      </div>
      <button
        *ngIf="!userLogedIn"
        (click)="onSignUp()"
        class="text-white border-2 border-solid border-white rounded-[50px] text-lg font-bold px-7 py-3.5 signup-btn transition-colors"
      >
        {{ 'footer.premiumButton' | translate }}
      </button>
    </div>
    <div
      class="grid grid-cols-2 md:grid-cols-4 pb-6 md:pb-20 pt-6 md:pt-20 lg:pt-0"
    >
      <div class="col-span-2 mb-6 md:mb-0">
        <div
          class="bg-oxford-blue rounded-xl px-8 py-4 border-dashed border border-indigo mb-5"
        >
          <h3 class="text-xl font-medium text-white mb-1">
            {{ 'footer.question1' | translate }}
          </h3>
          <p class="text-base text-gray-payne font-medium">
            {{ 'footer.emailUs' | translate }}
            <a href="mailto:support@joobzz.com" class="text-gray-cool">{{
              'footer.emailSupport' | translate
            }}</a>
          </p>
        </div>
        <div
          class="bg-oxford-blue rounded-xl px-8 py-6 border-dashed border border-indigo"
        >
          <h3 class="text-xl font-medium text-white mb-1">
            {{ 'footer.question2' | translate }}
          </h3>
          <p class="text-base text-gray-payne font-medium">
            {{ 'footer.emailUs' | translate }}
            <a href="mailto:feedback@joobzz.com" class="text-gray-cool">{{
              'footer.emailFeedback' | translate
            }}</a>
          </p>
        </div>
      </div>
      <div class="col-span-1 flex justify-start items-center flex-col">
        <ul class="text-gray text-sm font-medium space-y-4">
          <h3 class="text-gray-light font-semibold text-base mb-4">
            {{ 'footer.listOneTitle' | translate }}
          </h3>
          <!-- <li>
            <a routerLink="/impressum"> {{ 'footer.listOne1' | translate }} </a>
          </li> -->
          <li>
            <a routerLink="/privacy-policy">
              {{ 'footer.listOne2' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/user-agreement">
              {{ 'footer.listOne4' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/faq">
              {{ 'footer.listOne5' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/terms-of-use">
              {{ 'footer.listOne7' | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-span-1 flex justify-start items-center flex-col">
        <ul class="text-gray text-sm font-medium space-y-4">
          <h3 class="text-gray-light font-semibold text-base mb-4">
            {{ 'footer.listTwoTitle' | translate }}
          </h3>
          <li>
            <a
              href="https://www.facebook.com/joobzzrevolution/"
              class="flex gap-4"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_45_92)">
                  <path
                    d="M17.7604 0.849609H3.05952C1.70826 0.849609 0.609375 1.9485 0.609375 3.29975V18.0006C0.609375 19.3519 1.70826 20.4507 3.05952 20.4507H17.7604C19.1116 20.4507 20.2105 19.3519 20.2105 18.0006V3.29975C20.2105 1.9485 19.1116 0.849609 17.7604 0.849609Z"
                    fill="#1976D2"
                  />
                  <path
                    d="M17.149 10.6498H14.0863V8.19963C14.0863 7.52339 14.6351 7.58709 15.3114 7.58709H16.5364V4.52441H14.0863C12.0563 4.52441 10.4111 6.16968 10.4111 8.19963V10.6498H7.96094V13.7124H10.4111V20.4503H14.0863V13.7124H15.9239L17.149 10.6498Z"
                    fill="#FAFAFA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_45_92">
                    <rect
                      width="19.6011"
                      height="19.6011"
                      fill="white"
                      transform="translate(0.609375 0.849609)"
                    />
                  </clipPath>
                </defs>
              </svg>
              {{ 'footer.listTwo1' | translate }}
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/joobzzrevolution/"
              class="flex gap-4"
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_45_80)">
                  <path
                    d="M14.0852 0.177734H6.73473C3.35231 0.177734 0.609375 2.92067 0.609375 6.30309V13.6535C0.609375 17.0359 3.35231 19.7789 6.73473 19.7789H14.0852C17.4676 19.7789 20.2105 17.0359 20.2105 13.6535V6.30309C20.2105 2.92067 17.4676 0.177734 14.0852 0.177734ZM18.3729 13.6535C18.3729 16.0179 16.4495 17.9413 14.0852 17.9413H6.73473C4.37034 17.9413 2.44698 16.0179 2.44698 13.6535V6.30309C2.44698 3.9387 4.37034 2.01534 6.73473 2.01534H14.0852C16.4495 2.01534 18.3729 3.9387 18.3729 6.30309V13.6535Z"
                    fill="url(#paint0_linear_45_80)"
                  />
                  <path
                    d="M10.4081 5.07812C7.70191 5.07812 5.50781 7.27223 5.50781 9.97841C5.50781 12.6846 7.70191 14.8787 10.4081 14.8787C13.1143 14.8787 15.3084 12.6846 15.3084 9.97841C15.3084 7.27223 13.1143 5.07812 10.4081 5.07812ZM10.4081 13.0411C8.71995 13.0411 7.34542 11.6666 7.34542 9.97841C7.34542 8.28904 8.71995 6.91573 10.4081 6.91573C12.0962 6.91573 13.4708 8.28904 13.4708 9.97841C13.4708 11.6666 12.0962 13.0411 10.4081 13.0411Z"
                    fill="url(#paint1_linear_45_80)"
                  />
                  <path
                    d="M15.6764 5.36354C16.037 5.36354 16.3294 5.0712 16.3294 4.71058C16.3294 4.34996 16.037 4.05762 15.6764 4.05762C15.3158 4.05762 15.0234 4.34996 15.0234 4.71058C15.0234 5.0712 15.3158 5.36354 15.6764 5.36354Z"
                    fill="url(#paint2_linear_45_80)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_45_80"
                    x1="2.40367"
                    y1="17.9847"
                    x2="18.4162"
                    y2="1.97191"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFC107" />
                    <stop offset="0.507" stop-color="#F44336" />
                    <stop offset="0.99" stop-color="#9C27B0" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_45_80"
                    x1="6.94317"
                    y1="13.4433"
                    x2="13.873"
                    y2="6.51348"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFC107" />
                    <stop offset="0.507" stop-color="#F44336" />
                    <stop offset="0.99" stop-color="#9C27B0" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_45_80"
                    x1="15.2147"
                    y1="5.17237"
                    x2="16.1381"
                    y2="4.24891"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFC107" />
                    <stop offset="0.507" stop-color="#F44336" />
                    <stop offset="0.99" stop-color="#9C27B0" />
                  </linearGradient>
                  <clipPath id="clip0_45_80">
                    <rect
                      width="19.6011"
                      height="19.6011"
                      fill="white"
                      transform="translate(0.609375 0.177734)"
                    />
                  </clipPath>
                </defs>
              </svg>
              {{ 'footer.listTwo2' | translate }}
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/joobzzrevolution/"
              class="flex gap-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24"
                height="24"
                xml:space="preserve"
                version="1.1"
                viewBox="0 0 24 24"
              >
                <image
                  width="24"
                  height="24"
                  xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAA5tJREFUSEuNVcFuG1UUPfdlwwIpbqGoAlImVEjsav7AFkXBCEjyBXH+INlFxSHP1Eloukj+IM4X1FElhsYJY74A9wtqJBZRScERG9R47kFvxjMej52Kkax5nrlzz73nnnee4A2Xtxt4RsJFQEqq9AgUSfcBu6roAewMwvD43FZ616WRaS8+3Q28K9EtQqqMM8Ld3cr9je+M1/H7pmpYnwY0AXD30ekSYA5JFpIESWKXSbOAKZh7jj7A1Zd2oZUtegzgk72gquShK3GUyFU8rDZbedTWKC6Nh67+aSvNBCQFiCqneTKiYpKStINM5dPiobL8qhF3EgFEnBv+RqIw4hzrvQefH9zZbltCtlLOk27i0eQ6TWfVV8PP+rbSiwDuPvqlSXIly/nvD+6n3X3YaHMEMBx0OvRRpznAo36jUhUnxRnhizznYcjyH5tfdOa2T6qqchirMzfkvKpyM5IB5uXjnbM1CvanyhDskfBc4jF5XiPbqIAoOI6HYF3md09bgCzmAZRcF6IbEkWA+ySOQGkCAyjMQQjYV3ahVbAnRVG1IBdjikeqA+RYvN2zLsl7Q3mnQwMRUXTbtksQBkrWX9oF65gq2KDQt+V+Vu+Fmh/nSWiLZ9QVb+csWuZ3qOB6gFv2pBgqF43RXy9spROB1nxLYmtsIzqW7myfRsUn8owCHJcq5XPrOvBLChMASDu4uen3CHwESP/vh1/ecABv1/ySKINxqgmZa5x2Ibg3ofMcgAL1iyFFN7//OZVtv1GJ5JwA5NT4XOYa7RaBeMiZHQqG5XNb6bxr/RIoAcj6xQ+VaAY3Nv2oYfe73B4CbDwtUUyQU9uxfNBor4HYz+scjCmKAUyg1PpfQ4BCzR+6dhbAL1F0HIBYl9vW94yZeZHXuULLboAOQFUCgCnA7Hc/xR0A+Gfnq4iitzaelgQSAwylasxgPnr5/sOTpipWxra6co00XRUtgjgA2FQwcklVdBJZkyi5Z6GyCMFBhuqj13tfVyMA1wXFdEnMZlUwYWbZQ2fsAIrnl8QTcjljror//rgcm5273rPPlgA8mWoJQzr+rxcBWH69983IrhOQW9ZPjS0hOest6VGZmtz4Bo1tiKtXj7+dPHASkHdqz5YobBKcnfSWa/w/7vASYDWpPMk39dAvWN/DAG7rr6Q2MuXQz8zoyJiBdZxn/cmtpwIkQYUN39MZLpFSUqoHuB0f0fBciR6Ijpir1rTESY7/AM4TfI3zj9bbAAAAAElFTkSuQmCC"
                />
              </svg>
              {{ 'footer.listTwo3' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container-full relative">
    <div class="container px-3">
      <div
        class="flex flex-col-reverse gap-y-4 lg:flex-row lg:gap-y-0 justify-between py-4 items-center before:absolute before:w-full before:h-px before:bg-gray-payne before:top-0 before:left-0"
      >
        <a routerLink="/">
          <svg
            width="119"
            height="30"
            viewBox="0 0 119 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.1061 6.2112V24.0297C8.1061 27.653 6.53247 30 3.23649 30C2.0846 29.9981 0.958833 29.6567 0 29.0183L1.3059 26.1983C1.84629 26.4805 2.44845 26.6236 3.05801 26.6148C4.24789 26.5553 4.51264 25.6331 4.51264 24.0327V6.2112H8.1061ZM4.18542 2.11502C4.18542 4.93505 8.46009 4.93505 8.46009 2.11502C8.46009 -0.705007 4.18542 -0.705007 4.18542 2.11502Z"
              fill="white"
            />
            <path
              d="M73.7912 13.6068C73.7912 17.8249 70.9117 21.2399 66.1581 21.2399C61.4045 21.2399 58.5547 17.8249 58.5547 13.6068C58.5547 9.38861 61.464 5.97363 66.1283 5.97363C70.7927 5.97363 73.7882 9.41835 73.7882 13.6068H73.7912ZM62.1898 13.6068C62.1898 15.8348 63.5254 17.9141 66.17 17.9141C68.8145 17.9141 70.1501 15.8318 70.1501 13.6068C70.1501 11.3817 68.6063 9.27259 66.17 9.27259C63.5492 9.27259 62.1719 11.4114 62.1719 13.6068H62.1898Z"
              fill="white"
            />
            <path
              d="M78.9904 0.154297V8.26039C79.8501 6.74626 82.2626 5.91334 83.8005 5.91334C88.0781 5.91334 91.2551 8.52514 91.2551 13.5762C91.2551 18.6273 88.0187 21.2391 83.7113 21.2391C81.9264 21.2391 80.1178 20.6441 78.9904 18.892L78.7524 20.9119H75.3672V0.154297H78.9904ZM79.2284 13.5762C79.2284 16.2178 81.1887 17.8836 83.4763 17.8836C85.7638 17.8836 87.6409 16.1315 87.6409 13.5762C87.6409 11.0209 85.7995 9.29857 83.4763 9.29857C82.9127 9.28709 82.3528 9.39011 81.8302 9.6014C81.3077 9.81269 80.8335 10.1278 80.4363 10.5278C80.0392 10.9277 79.7273 11.4041 79.5197 11.9281C79.3121 12.4521 79.213 13.0128 79.2284 13.5762Z"
              fill="white"
            />
            <path
              d="M104.648 6.27051V7.99287L97.9069 17.3483H104.71V20.918H92.3828V19.3979L99.3912 9.58137H92.9183V6.27348H104.648V6.27051Z"
              fill="white"
            />
            <path
              d="M118.222 6.27051V7.99287L111.481 17.3483H118.281V20.918H105.957V19.3979L112.965 9.58137H106.495V6.27348H118.225L118.222 6.27051Z"
              fill="white"
            />
            <path
              d="M49.3012 21.5126H18.1084C16.0729 21.5126 14.1208 20.704 12.6815 19.2647C11.2422 17.8254 10.4336 15.8733 10.4336 13.8379C10.4336 11.8024 11.2422 9.85027 12.6815 8.41097C14.1208 6.97168 16.0729 6.16309 18.1084 6.16309H49.3012C51.3367 6.16309 53.2888 6.97168 54.7281 8.41097C56.1674 9.85027 56.976 11.8024 56.976 13.8379C56.976 15.8733 56.1674 17.8254 54.7281 19.2647C53.2888 20.704 51.3367 21.5126 49.3012 21.5126ZM18.1084 9.45015C17.5223 9.43489 16.9392 9.53713 16.3934 9.75085C15.8475 9.96457 15.3499 10.2854 14.9301 10.6945C14.5102 11.1036 14.1765 11.5926 13.9486 12.1327C13.7208 12.6729 13.6034 13.2531 13.6034 13.8393C13.6034 14.4255 13.7208 15.0058 13.9486 15.5459C14.1765 16.0861 14.5102 16.5751 14.9301 16.9842C15.3499 17.3932 15.8475 17.7141 16.3934 17.9278C16.9392 18.1415 17.5223 18.2438 18.1084 18.2285H49.3012C50.4455 18.1987 51.5329 17.7232 52.3318 16.9033C53.1306 16.0835 53.5776 14.984 53.5776 13.8393C53.5776 12.6946 53.1306 11.5952 52.3318 10.7753C51.5329 9.95544 50.4455 9.47994 49.3012 9.45015H18.1084Z"
              fill="white"
            />
            <path
              d="M22.5452 16.4289L21.382 15.2658C21.8177 14.6204 22.0014 13.8376 21.8984 13.0658C21.7953 12.2939 21.4127 11.5868 20.823 11.0783C20.2332 10.5698 19.4774 10.2954 18.6989 10.307C17.9203 10.3186 17.173 10.6155 16.5988 11.1414C16.0245 11.6673 15.6631 12.3856 15.5832 13.1602C15.5033 13.9347 15.7103 14.7117 16.1651 15.3438C16.6198 15.9759 17.2907 16.4191 18.0505 16.5895C18.8103 16.7599 19.6062 16.6456 20.2874 16.2683L21.4951 17.476C21.6347 17.6156 21.8241 17.6941 22.0216 17.6941C22.2191 17.6941 22.4085 17.6156 22.5481 17.476C22.6878 17.3364 22.7662 17.147 22.7662 16.9495C22.7662 16.752 22.6878 16.5626 22.5481 16.423L22.5452 16.4289ZM17.0657 13.4988C17.0634 13.1648 17.1604 12.8376 17.3445 12.559C17.5286 12.2803 17.7915 12.0626 18.0996 11.9337C18.4077 11.8048 18.7472 11.7705 19.0749 11.835C19.4027 11.8996 19.7038 12.0602 19.9399 12.2964C20.1761 12.5326 20.3367 12.8337 20.4013 13.1614C20.4659 13.4891 20.4315 13.8286 20.3026 14.1367C20.1737 14.4449 19.9561 14.7077 19.6774 14.8918C19.3987 15.0759 19.0715 15.173 18.7375 15.1706C18.2953 15.1667 17.8724 14.9893 17.5597 14.6767C17.247 14.364 17.0696 13.941 17.0657 13.4988Z"
              fill="#5BB3E4"
            />
          </svg>
        </a>
        <span class="text-gray-light text-sm font-medium">
          {{ 'footer.copyright' | translate }}@Joobzz 2023
        </span>
      </div>
    </div>
  </div>
</footer>
