import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  constructor(private http: HttpClient) {}

  mailExists(email: string) {
    return this.http.post<boolean>(
      `${environment.API_JOOBZZ}${environment.API_POST_USER_EXISTS}`,
      `"${email}"`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  couponExists(coupon: string) {
    const params = new HttpParams().set('couponId', coupon);

    return this.http.get<boolean>(
      `${environment.API_JOOBZZ}${environment.API_GET_COUPON_VALID}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params,
      }
    );
  }
}
