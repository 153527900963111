<form [formGroup]="signInstepOne" class="acc-type-selection">
  <mat-radio-group class="flex flex-col gap-y-[30px]" formControlName="type">
    <mat-radio-button
      value="1"
      class="acc-type cursor-pointer"
      [disableRipple]="true"
    >
      <div class="py-[18px] pr-4 flex items-center gap-x-5 cursor-pointer">
        <div class="icon-user">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="first"
              opacity="0.3"
              d="M17.0009 15.1111C12.828 15.1111 9.44531 11.7284 9.44531 7.55556C9.44531 3.38274 12.828 0 17.0009 0C21.1737 0 24.5564 3.38274 24.5564 7.55556C24.5564 11.7284 21.1737 15.1111 17.0009 15.1111Z"
              fill="#B5B5C3"
            />
            <path
              class="second"
              d="M0.00123096 32.4874C0.733377 23.4723 8.05028 18.8889 16.9685 18.8889C26.0123 18.8889 33.4426 23.2205 33.9961 32.4889C34.0181 32.8581 33.9961 34 32.5771 34C25.5777 34 15.1767 34 1.37417 34C0.900456 34 -0.03865 32.9785 0.00123096 32.4874Z"
              fill="#B5B5C3"
            />
          </svg>
        </div>
        <div class="text">
          <h3 class="font-bold text-dark">
            {{
              'home.signUpDialog.step1.accSelect.personal.optionTitle'
                | translate
            }}
          </h3>
          <p class="font-medium text-sm text-gray-light">
            {{
              'home.signUpDialog.step1.accSelect.personal.description'
                | translate
            }}
          </p>
        </div>
      </div>
    </mat-radio-button>
    <mat-radio-button
      value="2"
      class="acc-type cursor-pointer"
      [disableRipple]="true"
    >
      <div class="py-[18px] pr-4 flex items-center gap-x-5 cursor-pointer">
        <div class="icon-company">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="14" height="14" rx="1.5" fill="#B5B5C3" />
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 1.5C18 0.671573 18.6716 0 19.5 0H30.5C31.3284 0 32 0.671573 32 1.5V12.5C32 13.3284 31.3284 14 30.5 14H19.5C18.6716 14 18 13.3284 18 12.5V1.5ZM0 19.5C0 18.6716 0.671573 18 1.5 18H12.5C13.3284 18 14 18.6716 14 19.5V30.5C14 31.3284 13.3284 32 12.5 32H1.5C0.671573 32 0 31.3284 0 30.5V19.5ZM19.5 18C18.6716 18 18 18.6716 18 19.5V30.5C18 31.3284 18.6716 32 19.5 32H30.5C31.3284 32 32 31.3284 32 30.5V19.5C32 18.6716 31.3284 18 30.5 18H19.5Z"
              fill="#B5B5C3"
            />
          </svg>
        </div>
        <div class="text">
          <h3 class="font-bold text-dark">
            {{
              'home.signUpDialog.step1.accSelect.corporate.optionTitle'
                | translate
            }}
          </h3>
          <p class="font-medium text-sm text-gray-light">
            {{
              'home.signUpDialog.step1.accSelect.corporate.description'
                | translate
            }}
          </p>
        </div>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</form>
