import { CanActivateFn, Router } from '@angular/router';
import { AdminInfoService } from '../services/admin-info.service';
import { inject } from '@angular/core';

export const adminGuard: CanActivateFn = (route, state) => {
  const adminInfoService = inject(AdminInfoService);
  const router = inject(Router);

  return adminInfoService.isAdmin() ? true : router.navigate(['']);
};
