import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationStatus } from '../interfaces/notification-status.model';
import { SingleNotification } from '../interfaces/single-notification.model';
import { BehaviorSubject, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationSetStatus } from '../interfaces/notification-set-status.model';

@Injectable({
  providedIn: 'root',
})
export class CandidateNotificationsService {
  private candidateNotificationStatus = new BehaviorSubject<NotificationStatus>(
    {} as NotificationStatus
  );
  private candidateNotificatinsAll = new BehaviorSubject<SingleNotification[]>(
    [] as SingleNotification[]
  );

  candidateNotificationStatus$ =
    this.candidateNotificationStatus.asObservable();
  candidateNotificatinsAll$ = this.candidateNotificatinsAll.asObservable();

  constructor(private http: HttpClient) {}

  getCandidateNotificationStatus() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<NotificationStatus>(
        `${environment.API_JOOBZZ}${environment.API_GET_COMPANY_NOTIFICATIONS}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateNotificationStatus.next(res);
        },
      });
  }

  setCandidateNotificationStatus(status: NotificationStatus) {
    return this.http
      .post(
        `${environment.API_JOOBZZ}${environment.API_POST_COMPANY_NOTIFICATIONS_CHANGE}`,
        status
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  getAllCandidateNotifications() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<SingleNotification[]>(
        `${environment.API_JOOBZZ}${environment.API_GET_COMPANY_ALL_NOTIFICATIONS}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateNotificatinsAll.next(res);
        },
      });
  }

  setCandidateSingleNotificationStatus(statusUpdate: NotificationSetStatus) {
    return this.http
      .post(
        `${environment.API_JOOBZZ}${environment.API_POST_SET_NOTIFICATION_STATUS}`,
        statusUpdate,
        { responseType: 'text' }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  setCandidateNotificationRead(notificationId: number) {
    return this.http
      .get(
        `${environment.API_JOOBZZ}${environment.API_GET_SET_NOTIFICATION_READ}/${notificationId}`
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }
}
