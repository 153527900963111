<mat-form-field appearance="outline" class="w-[100px]">
  <span
    matPrefix
    style="margin-right: 8px; margin-left: 10px"
    class="flex w-14 items-center justify-center"
  >
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <path
        d="M13.2929 1.29289C13.6834 0.902369 14.3166 0.902369 14.7071 1.29289C15.0976 1.68342 15.0976 2.31658 14.7071 2.70711L8.70711 8.70711C8.32853 9.08568 7.71894 9.09893 7.32428 8.73715L1.32428 3.23715C0.917162 2.86396 0.889659 2.23139 1.26285 1.82428C1.63604 1.41716 2.26861 1.38965 2.67573 1.76285L7.96993 6.61586L13.2929 1.29289Z"
        fill="#7E8299"
        stroke="#7E8299"
      />
    </svg>
    <img [src]="langImgUrl" alt="" />
  </span>
  <mat-select
    [(value)]="selectedLang"
    style="width: fit-content; border: none"
    panelClass="lang-select"
    [formControl]="langSelectForm"
  >
    <mat-option
      *ngFor="let language of activeSelectListFlags"
      [value]="language.value"
    >
      <img [src]="language.img_url" alt="" />
      <span>{{ language.display_value }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
