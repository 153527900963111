import { LangSelectService } from './../../services/lang-select.service';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Lang } from 'src/app/interfaces/lang.model';
import { FormControl } from '@angular/forms';
import { DropdownDataService } from 'src/app/services/dropdown-data.service';

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss'],
})
export class LangSelectComponent implements OnInit, OnDestroy {
  languages: Lang[] = [];
  selectedLang = '';
  langSelectForm = new FormControl('en');
  langImgUrl = '';
  activeFlag = {} as Lang[];
  activeSelectListFlags = {} as Lang[];

  funcListener: any;

  constructor(private langSelectService: LangSelectService) {
    this.langSelectForm.valueChanges.subscribe((res) => {
      if (res === null) {
        res = 'en';
      } else {
        this.langSelectService.setActiveLang(res);
        this.setActiveFlag(res);
        this.filterFlags(res);
      }
    });
  }

  // @HostListener('window:focus', ['$event'])
  // onFocus(event: any): void {
  //   console.log(event);
  //   this.setActiveFlag(this.langSelectService.getInitialLanguage());
  //   this.filterFlags(this.langSelectService.getInitialLanguage());
  //   this.langSelectForm.setValue(this.langSelectService.getInitialLanguage());
  //   this.langSelectService.setActiveLang(
  //     this.langSelectService.getInitialLanguage()
  //   );
  // }

  ngOnInit(): void {
    this.languages = this.langSelectService.getLanguages();
    this.langSelectForm.setValue(this.langSelectService.getInitialLanguage());
    this.langImgUrl = this.languages[0].img_url;
    this.setActiveFlag(this.langSelectService.getInitialLanguage());
    this.filterFlags(this.langSelectService.getInitialLanguage());

    this.funcListener = this.func.bind(this);
    window.addEventListener('storage', this.funcListener);
  }

  func(event: StorageEvent): void {
    // console.log(event);
    if (event.key == 'appLang') {
      // console.log(event);
      if (event.newValue !== event.oldValue) {
        if (event.newValue === 'de-DE') {
          this.setActiveFlag('de');
          this.filterFlags('de');
          this.langSelectForm.setValue('de');
          this.langSelectService.setActiveLang('de');
        }
        if (event.newValue === 'en-US') {
          this.setActiveFlag('en');
          this.filterFlags('en');
          this.langSelectForm.setValue('en');
          this.langSelectService.setActiveLang('en');
        }
        if (event.newValue === 'fr-FR') {
          this.setActiveFlag('fr');
          this.filterFlags('fr');
          this.langSelectForm.setValue('fr');
          this.langSelectService.setActiveLang('fr');
        }
      }
    }
  }

  setActiveFlag(lang: string) {
    this.activeFlag = this.languages.filter((e) => {
      return e.value === lang;
    });
    this.langImgUrl = this.activeFlag[0].img_url;
  }

  filterFlags(lang: string) {
    this.activeSelectListFlags = this.languages.filter((e) => {
      return e.value !== lang;
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.funcListener);
  }
}
