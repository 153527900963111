import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { cookieConfirmDialog } from './components/cookie-dialog/cookie-dialog.component';
import { CookieConfirmService } from './services/cookie-confirm.service';
import { LangSelectService } from './services/lang-select.service';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  noUser = this.logInService.noUser$;
  userClient = this.logInService.clientLoggedIn$;
  userCompany = this.logInService.companyLoggedIn$;
  userAdmin = this.logInService.adminLoggedIn$;
  registeringUser = this.logInService.registering$;

  constructor(
    private langSelectService: LangSelectService,
    private dialog: MatDialog,
    private cookieConfirmService: CookieConfirmService,
    private logInService: LoginService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    this.langSelectService.setInitalLanguage();

    let userRole = '';
    if (localStorage.getItem('userRole')) {
      userRole = localStorage.getItem('userRole') as string;
      if (userRole === 'Candidate') {
        this.logInService.clientLogIn();
      }
      if (userRole === 'Company') {
        this.logInService.companyLogIn();
      }
      if (userRole === 'Admin') {
        this.logInService.logOut();
        this._document.location.href = environment.APP_ADMIN_ROUTE;
      }
    }

    // openLoginDialog(this.dialog);
    // singnUpDialog(this.dialog);
    // openResetPassDialog(this.dialog);
  }
}
