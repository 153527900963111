import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CandidateOverview } from '../interfaces/candidate-overview.model';
import { SingleNotification } from '../interfaces/single-notification.model';
import { CandidateInfoService } from '../services/candidate-info.service';
import { CandidateNotificationsService } from '../services/candidate-notifications.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-client-header',
  templateUrl: './client-header.component.html',
  styleUrls: ['./client-header.component.scss'],
})
export class ClientHeaderComponent implements OnInit {
  isMenuShown = '-translate-x-full';
  notificationsShown = 'hidden';
  logoutMenuShown = 'hidden';

  candidateOverview$: Observable<CandidateOverview> =
    this.candidateInfoService.candidateOverview$;

  candidateNotificationsAll$: Observable<SingleNotification[]> =
    this.candidateNotificationService.candidateNotificatinsAll$.pipe(
      map((notifications) => {
        return notifications.filter((e) => {
          return e.read === false;
        });
      })
    );

  constructor(
    private loginService: LoginService,
    private candidateInfoService: CandidateInfoService,
    private candidateNotificationService: CandidateNotificationsService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.candidateInfoService.getCandidateOverview();
    this.candidateNotificationService.getAllCandidateNotifications();
  }

  onMenuClicked() {
    if (this.isMenuShown === '-translate-x-full') {
      this.isMenuShown = '';
    } else {
      this.isMenuShown = '-translate-x-full';
    }
  }
  closeMenu() {
    this.isMenuShown = '-translate-x-full';
  }

  onNotificationsClicked() {
    if (this.notificationsShown === 'hidden') {
      this.notificationsShown = '';
    } else {
      this.notificationsShown = 'hidden';
    }
  }

  onLogoutMenuClicked() {
    if (this.logoutMenuShown === 'hidden') {
      this.logoutMenuShown = '';
    } else {
      this.logoutMenuShown = 'hidden';
    }
  }

  @HostListener('document:click', ['$event']) clickOutside($event: Event) {
    $event.stopPropagation();
    if (
      !this.elementRef.nativeElement
        .querySelector('.notifications-header-client')
        .contains($event.target)
    ) {
      this.closeNotifications();
    }
    if (
      !this.elementRef.nativeElement
        .querySelector('.logoout-header-company')
        .contains($event.target)
    ) {
      this.closeLogout();
    }
  }

  closeNotifications() {
    this.notificationsShown = 'hidden';
  }

  closeLogout() {
    this.logoutMenuShown = 'hidden';
  }

  onLogOut() {
    this.loginService.logOut();
  }
}
