<div class="w-full sm:min-w-[430px]">
  <div class="w-full mb-7 sm:mb-14">
    <svg
      class="h-auto max-w-full mx-auto"
      width="157"
      height="40"
      viewBox="0 0 175 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9736 9.17463V35.4946C11.9736 40.8464 9.64918 44.3133 4.78065 44.3133C3.07919 44.3106 1.4163 43.8062 0 42.8633L1.92896 38.6978C2.72718 39.1146 3.61663 39.326 4.51701 39.3129C6.2746 39.2251 6.66567 37.8629 6.66567 35.499V9.17463H11.9736ZM6.18233 3.12412C6.18233 7.28961 12.4965 7.28961 12.4965 3.12412C12.4965 -1.04137 6.18233 -1.04137 6.18233 3.12412Z"
        fill="#005AAA"
      />
      <path
        d="M108.998 20.098C108.998 26.3286 104.745 31.3729 97.7232 31.3729C90.7016 31.3729 86.4922 26.3286 86.4922 20.098C86.4922 13.8673 90.7895 8.823 97.6793 8.823C104.569 8.823 108.994 13.9112 108.994 20.098H108.998ZM91.8616 20.098C91.8616 23.389 93.8345 26.4604 97.7408 26.4604C101.647 26.4604 103.62 23.3846 103.62 20.098C103.62 16.8113 101.339 13.6959 97.7408 13.6959C93.8697 13.6959 91.8353 16.8552 91.8353 20.098H91.8616Z"
        fill="#005AAA"
      />
      <path
        d="M116.676 0.228516V12.2021C117.946 9.96558 121.509 8.73526 123.781 8.73526C130.1 8.73526 134.792 12.5932 134.792 20.0542C134.792 27.5151 130.012 31.373 123.649 31.373C121.013 31.373 118.341 30.4942 116.676 27.9062L116.325 30.8897H111.324V0.228516H116.676ZM117.028 20.0542C117.028 23.956 119.923 26.4166 123.302 26.4166C126.681 26.4166 129.454 23.8286 129.454 20.0542C129.454 16.2797 126.734 13.7356 123.302 13.7356C122.47 13.7186 121.643 13.8708 120.871 14.1829C120.099 14.495 119.399 14.9605 118.812 15.5513C118.225 16.142 117.765 16.8457 117.458 17.6197C117.151 18.3937 117.005 19.2219 117.028 20.0542Z"
        fill="#005AAA"
      />
      <path
        d="M154.577 9.26245V11.8066L144.621 25.6256H154.67V30.8984H136.461V28.6531L146.813 14.153H137.252V9.26685H154.577V9.26245Z"
        fill="#005AAA"
      />
      <path
        d="M174.624 9.26245V11.8066L164.667 25.6256H174.712V30.8984H156.508V28.6531L166.86 14.153H157.303V9.26685H174.629L174.624 9.26245Z"
        fill="#005AAA"
      />
      <path
        d="M72.8219 31.7772H26.7466C23.74 31.7772 20.8565 30.5828 18.7305 28.4568C16.6045 26.3308 15.4102 23.4473 15.4102 20.4407C15.4102 17.4341 16.6045 14.5506 18.7305 12.4246C20.8565 10.2986 23.74 9.10425 26.7466 9.10425H72.8219C75.8285 9.10425 78.712 10.2986 80.838 12.4246C82.964 14.5506 84.1584 17.4341 84.1584 20.4407C84.1584 23.4473 82.964 26.3308 80.838 28.4568C78.712 30.5828 75.8285 31.7772 72.8219 31.7772ZM26.7466 13.9596C25.881 13.9371 25.0197 14.0881 24.2134 14.4038C23.4071 14.7195 22.6721 15.1934 22.0519 15.7977C21.4318 16.4019 20.9388 17.1243 20.6023 17.9221C20.2657 18.7199 20.0923 19.577 20.0923 20.4429C20.0923 21.3088 20.2657 22.1659 20.6023 22.9637C20.9388 23.7616 21.4318 24.4839 22.0519 25.0882C22.6721 25.6924 23.4071 26.1664 24.2134 26.4821C25.0197 26.7977 25.881 26.9488 26.7466 26.9262H72.8219C74.5122 26.8822 76.1184 26.1799 77.2983 24.9688C78.4783 23.7577 79.1386 22.1337 79.1386 20.4429C79.1386 18.7521 78.4783 17.1281 77.2983 15.917C76.1184 14.706 74.5122 14.0036 72.8219 13.9596H26.7466Z"
        fill="#005AAA"
      />
      <path
        d="M33.3045 24.2679L31.5864 22.5498C32.23 21.5965 32.5013 20.4402 32.3491 19.3001C32.1969 18.1601 31.6317 17.1155 30.7606 16.3644C29.8895 15.6133 28.7731 15.2079 27.6231 15.2251C26.473 15.2423 25.3692 15.6809 24.521 16.4577C23.6727 17.2345 23.139 18.2955 23.0209 19.4396C22.9028 20.5837 23.2087 21.7314 23.8804 22.665C24.5521 23.5987 25.5431 24.2534 26.6654 24.5051C27.7877 24.7568 28.9633 24.588 29.9695 24.0306L31.7534 25.8145C31.9597 26.0208 32.2394 26.1367 32.5311 26.1367C32.8228 26.1367 33.1026 26.0208 33.3089 25.8145C33.5151 25.6083 33.631 25.3285 33.631 25.0368C33.631 24.7451 33.5151 24.4653 33.3089 24.2591L33.3045 24.2679ZM25.2108 19.9398C25.2073 19.4464 25.3506 18.9632 25.6226 18.5515C25.8945 18.1399 26.2827 17.8184 26.7379 17.628C27.193 17.4376 27.6945 17.3868 28.1786 17.4822C28.6627 17.5776 29.1074 17.8148 29.4563 18.1637C29.8051 18.5126 30.0424 18.9573 30.1378 19.4414C30.2332 19.9254 30.1824 20.4269 29.992 20.8821C29.8016 21.3372 29.4801 21.7255 29.0685 21.9974C28.6568 22.2693 28.1735 22.4127 27.6802 22.4092C27.027 22.4035 26.4023 22.1415 25.9404 21.6796C25.4785 21.2177 25.2165 20.5929 25.2108 19.9398Z"
        fill="#5BB3E4"
      />
    </svg>
  </div>
  <h2 class="font-bold text-dark text-xl mb-2">
    {{ 'home.signUpDialog.step2.title' | translate }}
  </h2>
  <p class="font-medium text-sm text-gray-light mb-5 sm:mb-10">
    {{ 'home.signUpDialog.step2.description' | translate }}
  </p>
  <form class="flex flex-col login-form mt-9" [formGroup]="signInStepTwo">
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="{{ 'home.signUpDialog.step2.form.firstName' | translate }}"
        formControlName="firstName"
      />
      <mat-error *ngIf="firstName.hasError('required')">
        {{ 'home.signUpDialog.step2.form.fieldRequired' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="{{ 'home.signUpDialog.step2.form.lastName' | translate }}"
        formControlName="lastName"
      />
      <mat-error *ngIf="lastName.hasError('required')">
        {{ 'home.signUpDialog.step2.form.fieldRequired' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="{{ 'home.signUpDialog.step2.form.email' | translate }}"
        formControlName="email"
      />
      <mat-error *ngIf="email.hasError('required')">
        {{ 'home.signUpDialog.step2.form.fieldRequired' | translate }}
      </mat-error>
      <mat-error *ngIf="email.hasError('email')">
        {{ 'home.loginDialog.loginErrors.emailEmail' | translate }}
      </mat-error>
      <mat-error *ngIf="email.hasError('userExists')">
        {{ 'home.signUpDialog.step2.form.userExistsError' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-checkbox
      formControlName="privacy"
      [disableRipple]="true"
      checked="false"
      class="custom-frame mb-5 sm:mb-10"
    >
      <p class="font-medium text-sm text-gray-payne">
        {{ 'home.signUpDialog.step2.form.iAccept' | translate }}
        <a [routerLink]="['privacy-policy']" target="_blank" class="btn-clear">
          {{ 'home.signUpDialog.step2.form.privacyPolicy' | translate }}
        </a>
      </p>
      <mat-error
        class="text-xs"
        *ngIf="privacy.hasError('required') && !signInStepTwo.pristine"
      >
        {{ 'home.signUpDialog.step2.form.fieldRequired' | translate }}
      </mat-error>
    </mat-checkbox>
  </form>
</div>
