import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-finished-welcome',
  templateUrl: './finished-welcome.component.html',
  styleUrls: ['./finished-welcome.component.scss'],
})
export class FinishedWelcomeComponent {
  @Input() firstName = '';
  @Input() lastName = '';
}
