import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotConfirmedRecruiter } from '../interfaces/not-confirmed-recruiter.model';
import { BehaviorSubject, retry, switchMap } from 'rxjs';
import { AdminCandidateViewList } from '../interfaces/admin-candidate-view-list.model';
import { AdminCompanyViewList } from '../interfaces/admin-company-view-list.model';
import { AdminCandidatesNotificationsList } from '../interfaces/admin-candidates-notifications-list.model';
import { AdminRecruiterNotificationsList } from '../interfaces/admin-recruiter-notifications-list.model';
import { AdminCandidatePaymentViewList } from '../interfaces/admin-candidate-payment-view-list.model';
import { RecruiterActivities } from '../interfaces/recruiter-activities.model';

@Injectable({
  providedIn: 'root',
})
export class AdminInfoService {
  private notConfirmedRecruiters = new BehaviorSubject<NotConfirmedRecruiter[]>(
    []
  );
  private candidatesList = new BehaviorSubject<AdminCandidateViewList>(
    {} as AdminCandidateViewList
  );
  private recruiterList = new BehaviorSubject<AdminCompanyViewList>(
    {} as AdminCompanyViewList
  );
  private candidateNotificationsList =
    new BehaviorSubject<AdminCandidatesNotificationsList>(
      {} as AdminCandidatesNotificationsList
    );
  private recruiterNotificationsList =
    new BehaviorSubject<AdminRecruiterNotificationsList>(
      {} as AdminRecruiterNotificationsList
    );

  private candidatePaymentList =
    new BehaviorSubject<AdminCandidatePaymentViewList>(
      {} as AdminCandidatePaymentViewList
    );

  notConfirmedRecruiters$ = this.notConfirmedRecruiters.asObservable();
  candidatesList$ = this.candidatesList.asObservable();
  recruiterList$ = this.recruiterList.asObservable();
  candidateNotificationsList$ = this.candidateNotificationsList.asObservable();
  recruiterNotificationsList$ = this.recruiterNotificationsList.asObservable();
  candidatePaymentList$ = this.candidatePaymentList.asObservable();

  constructor(private http: HttpClient) {}

  getNotConfirmedRecruiter() {
    return this.http
      .get<NotConfirmedRecruiter[]>(
        `${environment.API_JOOBZZ}${environment.API_GET_ADMIN_NOT_CONFIRMED_RECRUITERS}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.notConfirmedRecruiters.next(res);
        },
      });
  }

  confirmRecruiter(userId: string, lang: number) {
    let language = '';
    if (lang === 1) {
      language = 'en-US';
    } else if (lang === 2) {
      language = 'de-DE';
    } else if (lang === 3) {
      language = 'fr-FR';
    }
    return this.http.get<NotConfirmedRecruiter[]>(
      `${environment.API_JOOBZZ}${environment.API_GET_ADMIN_APPROVE_USER}/${userId}`,
      {
        headers: new HttpHeaders({ 'Accept-Language': language }),
      }
    );
  }

  getCandidates(
    pageIndex = 0,
    pageSize = 10,
    sortBy = 'createdAt',
    sortAsc = false,
    searchValue = ''
  ) {
    const params = new HttpParams()
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('sortBy', sortBy)
      .set('sortAsc', sortAsc)
      .set('searchValue', searchValue);
    return this.http
      .get<AdminCandidateViewList>(
        `${environment.API_JOOBZZ}${environment.API_GET_ADMIN_ALL_CANDIDATES}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidatesList.next(res);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getRecruiters(
    pageIndex = 0,
    pageSize = 10,
    sortBy = 'createdAt',
    sortAsc = false,
    searchValue = ''
  ) {
    const params = new HttpParams()
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('sortBy', sortBy)
      .set('sortAsc', sortAsc)
      .set('searchValue', searchValue);
    return this.http
      .get<AdminCompanyViewList>(
        `${environment.API_JOOBZZ}${environment.API_GET_ADMIN_ALL_RECRUITERS}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.recruiterList.next(res);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getCandidatesNotifications(
    pageIndex = 0,
    pageSize = 10,
    sortBy = 'createdAt',
    sortAsc = false,
    searchValue = ''
  ) {
    const params = new HttpParams()
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('sortBy', sortBy)
      .set('sortAsc', sortAsc)
      .set('searchValue', searchValue);
    return this.http
      .get<AdminCandidatesNotificationsList>(
        `${environment.API_JOOBZZ}${environment.API_GET_ADMIN_CANDIDATES_NOTIFICATIONS}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidateNotificationsList.next(res);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getRecruiterNotifications(
    pageIndex = 0,
    pageSize = 10,
    sortBy = 'createdAt',
    sortAsc = false,
    searchValue = ''
  ) {
    const params = new HttpParams()
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('sortBy', sortBy)
      .set('sortAsc', sortAsc)
      .set('searchValue', searchValue);
    return this.http
      .get<AdminRecruiterNotificationsList>(
        `${environment.API_JOOBZZ}${environment.API_GET_ADMIN_RECRUITERS_NOTIFICATIONS}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.recruiterNotificationsList.next(res);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getCandidatePayments(
    pageIndex = 0,
    pageSize = 10,
    sortBy = 'candidateId',
    sortAsc = false,
    searchValue = ''
  ) {
    const params = new HttpParams()
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('sortBy', sortBy)
      .set('sortAsc', sortAsc)
      .set('searchValue', searchValue);
    return this.http
      .get<AdminCandidatePaymentViewList>(
        `${environment.API_JOOBZZ}${environment.API_GET_CANDIDATE_PAYMENTS}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.candidatePaymentList.next(res);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  deleteRecruiter(recruiterId: number, userEmail: string) {
    return this.http
      .delete(
        `${environment.API_JOOBZZ}${environment.API_DELETE_RECRUITER}/${recruiterId}`
      )
      .pipe(
        switchMap((res) => {
          return this.http.post(
            `${environment.API_JOOBZZ}${environment.API_POST_REMOVE_USER}`,
            `"${userEmail}"`,
            {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
              }),
            }
          );
        })
      );
  }

  isAdmin() {
    const role = localStorage.getItem('userRole');
    if (role === 'Admin') {
      return true;
    } else {
      return false;
    }
  }
}
