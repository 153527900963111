import { CanActivateFn, Router } from '@angular/router';
import { CompanyInfoService } from '../services/company-info.service';
import { inject } from '@angular/core';
import { CandidateInfoService } from '../services/candidate-info.service';

export const candidateAndCompanyGuard: CanActivateFn = (route, state) => {
  const companyInfoService = inject(CompanyInfoService);
  const candidateInfoService = inject(CandidateInfoService);
  const router = inject(Router);

  if (candidateInfoService.isCandidate() || companyInfoService.isRecruiter()) {
    return true;
  } else {
    return router.navigate(['']);
  }
};
