import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const lang = localStorage.getItem('appLang');
    if (lang) {
      if (request.url.includes('AdminDashboard/approve')) {
        return next.handle(request.clone());
      } else {
        const cloned = request.clone({
          headers: request.headers.set('Accept-Language', `${lang}`),
        });
        return next.handle(cloned);
      }
    } else {
      const cloned2 = request.clone({
        headers: request.headers.set('Accept-Language', `en-US`),
      });
      return next.handle(cloned2);
    }
  }
}
